.main__content__tab__wrapper{
    font-size: 13px;
    padding: 15px 0 20px 0;
    ul{
        display: flex;
        li{
            padding: 5px 10px;
            a{
                color: $darkgrey;
                position: relative;
                text-decoration: none;
                // &:before{
                //     content: "";
                //     position: absolute;
                //     width: 100%;
                //     height: 2px;
                //     bottom: 0;
                //     left: 0;
                //     background-color: $darkgrey;
                //     visibility: hidden;
                //     transition: all 0.3s ease-in-out 0s;
                //     transform: scaleX(0);
                //     margin-bottom: -2px;
                // }
                // &:hover{
                //     color: $darkgrey;
                //     &:before{
                //         visibility: visible;
                //         transform: scaleX(1);
                //     }
                // }
            }
        }
    }
}


    #box,
    #box2{
      position:relative;
    }
    a.active{
        color: $color-main;
        transition: all .9s;
    }

    #menu li.slide-line,
    #menu2 li.slide-line {
        display: block;
        padding:0;
        margin:0;
        background: none $blueregular;
        position: absolute;
        width: 15px; 
        height: 2px; 
        left: 10px; 
        // top: 25px;
        bottom: 0;
        z-index: 0;
    }