/* 
 |------------------------------------------------------------------+
 ||-----------------------------------------------------------------+
 ||	VARIBLE COLOR													+
 ||																	+
*/

$bluedark: #4a90e2;
$blueregular: #44C7F4;
$bluelight: #9FBFCB;
$white: #ffffff;
$isabelline: #f4f2ef;
$stormgrey: #71828B;
$lightsky: #627F8F;



$lightgrey: #F3F4F6;  //lightgrey
$dimgrey: #4A4A4A;  //dimgrey
$darkgrey: #adb2bf; //darkgrey
$darkergrey: #9B9B9B;

$white: #ffffff;
$charcoal: #464646;
$whitesmoke: #F3F3F3;
$limegreen: #33CC33;
$darkgreen: #276729;
$verylightwhite: #CECECE;
$grey: #868686;
$semilightgrey: #9FA197;
$eclipse: #363636;
$gainsboro: #E1E1E1;

$color-sidebar: $white;
$color-sidebar-accordion-open: darken($white, 0.5%);
$color-sidebar-accordion-hover: darken($white, 0.5%);
$color-sidebar-accordion-active: $charcoal;
$color-sidebar-accordion-text: $semilightgrey;
$color-sidebar-accordion-text-hover: $charcoal;
$color-sidebar-accordion-border: $isabelline;

$color-header-background: $charcoal;
$color-header-title: $white;
$color-header-title-hover: $limegreen;
$color-header-breadcrumb-active: $limegreen;
$color-header-dropdown-title: $dimgrey;
$color-header-dropdown-text: $charcoal;
$color-header-dropdown-text-hover: darken($charcoal, 5%);
$color-header-dropdown-border: $charcoal;
$color-header-dropdown-border-content: $charcoal;
$color-header-toogle-bar: $white;
$color-header-toogle-hover: lighten($charcoal, 10%);

$color-filter-title: $charcoal;

// card
$color-card-title: $charcoal;
$color-card-desc-text: $charcoal;
$color-card-desc-label: $grey;
$color-button-text: $charcoal;

$color-button-text: $white;
$color-button-bg: $charcoal;
$color-button-hover: $limegreen;
$color-button-cancel-bg: $gainsboro;
$color-button-cancel-text: $charcoal;
$color-button-cancel-text2: $grey;

$color-button-upload: $limegreen;
$color-button-remove-with-text: $verylightwhite;
$color-button-remove-with-text-hover: $charcoal;

$color-text-big: $eclipse;
$color-text-small: $grey;
$color-text-small-placeholder: $dimgrey;
$color-text-hover: $charcoal;
$color-text-form-group: $semilightgrey;

/* paging */
$color-paging-bg: $lightgrey;
$color-paging-border: $lightgrey;
$color-paging-text-hover: $charcoal;
/* form */
$color-form-text-label: $charcoal;
$color-form-input-border: $darkergrey;
/* drag */
$color-form-drag-title: $semilightgrey;

/* datepicker */
$color-datepicker-text-title: $limegreen;
$color-datepicker-text-day-title: lighten($limegreen, 10%);
$color-datepicker-arrow: lighten($limegreen, 10%);
$color-datepicker-date-hover: darken($limegreen, 10%);
$color-datepicker-date-current: darken($limegreen, 10%);
$color-datepicker-date: $charcoal;

/* timepicker */
$color-timepicker-title-active: $blueregular;
$color-timepicker-clock-line: $blueregular;
$color-timepicker-clock-bearing: $blueregular;
$color-timepicker-clock-bg: lighten($blueregular, 30%);

/* popup */
$color-popup-title: $charcoal;
$color-popup-button-close: $verylightwhite;
/* popup change pass */
$color-popup-bg-notif: $semilightgrey;
$color-popup-text-label: $charcoal;

/* table */
$color-table-th: $grey;
$color-table-td: $charcoal;
$color-table-icon-hover: $limegreen;

/* select dropdown */
$color-select-arrow: $charcoal;

/* tags in card */
$color-tags-bg: $lightgrey;
$color-tags-text: $charcoal;


/* checkbox active on click */
$color-checkbox-active: $charcoal;
/* checkbox grid in admin */
$color-checkbox-grid-border: $darkergrey;
/*checkbox label */
$color-checkbox-label: $dimgrey;

/* radio button style */
$color-radio-dot: $charcoal;
$color-radio-label: $dimgrey;

/* notification */
$color-notification: $semilightgrey;

/* select2 */
$color-select2-hover: $lightgrey;

/* upload photo */
$color-button-upload-preview: $limegreen;

$color-line: $lightgrey;

$color-drag-handle: $lightgrey;

$color-main: $white;

/* login */
$color-login-content-bg: $white;
$color-login-text-title: $charcoal;
$color-login-text-small: $darkgrey;
$color-login-border-input: $charcoal;
$color-login-button-bg: $charcoal;
$color-login-button-text: $white;



$color-bg-body: $lightgrey;  
$color-bg-hover: $lightgrey;
$color-bg-button: $bluelight; 

$color-text-main: $lightsky;
$color-text-main-lighter: $bluelight; 
$color-text-small2: $dimgrey;

$color-border: $bluelight;
$color-border-form: $darkgrey;
$color-border-button-gray: $lightgrey;



/* SEO */
$color-seo-text-label: $charcoal;
$color-seo-border-button: $charcoal;


/* 
 |------------------------------------------------------------------+
 ||-----------------------------------------------------------------+
 ||	VARIBLE FONT													+
 ||																	+
*/

$font-main: 'proximanova.regular' !default;
$font-main-light: 'proximanova.light';
$font-main-semibold: 'proximanova.semibold';
$font-main-bold: 'proximanova.bold';

$font-second-regular: 'brandon_regular';
$font-second-medium: 'brandon_medium';
$font-second-light: 'brandon_light';
$font-second-black: 'brandon_black';
$font-second-bold: 'brandon_bold';

$font-third-regular: 'harriet_regular';
$font-third-medium: 'harriet_medium';
$font-third-light: 'harriet_light';
$font-third-black: 'harriet_black';
$font-third-bold: 'harriet_bold';

$font-h1: $font-main;
$font-h2: $font-main;


// SIDEBAR
$font-sidebar: $font-main;

// HEADER
$font-header-title: $font-main;
$font-header-status-dropdown: $font-main-semibold;

// FORM
$font-form-top: $font-main;
$font-form-bottom: $font-main-semibold;
$font-form-group-title: $font-main;

// BUTTON
$font-button-remove-with-text: $font-main-semibold;

// CARD
$font-card-title: $font-main-semibold;
$font-card-desc-name: $font-main-semibold;
$font-card-button: $font-main-semibold;

// POPUP
$font-popup-title: $font-main;





// SEO
$font-seo-text-label: $font-main;