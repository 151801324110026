/* filter */
.filter__wrapper{
    height: auto;
    background: #ffffff;
    box-shadow: 2px 0px 4px 0px rgba(32, 45, 77, 0.1);
    width: 100%;
    position: relative;
    top: 1px;
    height: 80px;
    display: flex;
    justify-content: space-between;
    .filter__content{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: 0 20px;
        width: inherit;
        h1{
            color: $color-filter-title;
        }
        .filter__content__btn{
            position: relative;
            display: flex;
            .btn__content__filter{
                // height: 22px;
                font-size: 11px;
                margin: 0 5px;
                text-transform: uppercase;
                letter-spacing: 0.58px;
                i.icon-update {
                    display: flex;
                    margin-right: 2px;
                }
            }
        }
        .filter__content__form{
            .filter__content__form__label{
                font-size: 11px;
                color: $color-form-text-label;
                line-height: 21px;
                display: block;
            }
            // .filter__content__form__dropdown{
            //     font-family: 'lato-regular';
            //     .dropdown__wrapper{
            //         border-radius: 3px;
            //     }
            // }
        }
    }
    .input-icon{
        display: flex;
        position: relative;
        input{
            width: 100%;
        }
        .icon__wrapper{
            width: 30px;
            height: 30px;
            position: absolute;
            border: 1px solid #adb2bf;
            right: 0;
            cursor: pointer;
            transition: all .2s;
            i{
                width: 100%;
                height: 100%;
                position: absolute;
                background-position: center;
                background-size: 15px;
            }
            &:hover{
                background-color: darken($color-main, 5%);
            }
        }
        &.input-icon__time{
            .icon__wrapper{
                width: 16px;
                i{
                    background-size: 20px;
                }
            }
        }
    }
    .filter__date{
        .input-icon{
            margin-left: 10px;
        }
    }
    .filter__input{
        .field__search__function{
            margin-left: 10px;
        }
        .select2{
            margin-left: 10px;
            width: 100px;
        }
    }
}