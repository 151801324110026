.btn__filter{
	color: $color-button-text;
	border-radius: 4px;
	font-size: 12px;
	position: relative;
	transition: all .3s;
	padding: 9px 13px 7px 13px;
	border-radius: 4px;
	text-align: center;
	background: $color-button-bg;
    &:before{            
        content: "+";
        width: 16px;
        height: 16px;
        background: $color-button-bg;
        border-radius: 100%;
        position: absolute;
        left: -10px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        font-size: 16px;
        font-weight: bold;
        padding: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(0deg);
        transition: all .4s;
    }
    &:hover{
        background: lighten($color-button-bg, 5%);
        &:before{
            background: lighten($color-button-bg, 5%);
        }
    }
}
.btn__filter__cancel{
	@extend .btn__filter;

	border: 1px solid $color-border-button-gray;
	background: $color-button-cancel-bg;
	color: $color-button-cancel-text;
	&:before{
        content: "+";
        background: $color-button-cancel-bg;
        transform: rotate(135deg);
        color: $color-button-cancel-text2;
        transition: all .4s;
    }
    &:hover{
        background: darken($color-button-cancel-bg, 5%);
        &:before{
            background: darken($color-button-cancel-bg, 5%);
        }
    }
}
.btn__filter__download{
    @extend .btn__filter;

    &:before{
        content: "\f019";
        font-family: 'FontAwesome';
        font-size: 9px;
    }
}

.action__btn{
    .btn__delete{
        padding: 3px 11px;
        .icon__delete{
            background-size: 11px;
        }
    }
}
.img__file--btn{
    .btn__edit{
        padding: 4px 13px;
        font-size: 11px;
        color: $color-text-main;
        background: $color-bg-hover;
        border: 1px solid $color-bg-hover;
        &:before{
            top: 1px;
            position: relative;
        }
    }
}

.btn__edit{
    border: 1px solid lighten(#dddee3, 5%);
    padding: 0 13px;
    background-color: $color-button-cancel-bg;
    border-radius: 3px;
    color: $color-text-small;
    font-size: 13px;
    position: relative;
    transition: all .3s;
    height: 31px;
    display: flex;
    align-items: center;
    // &:before{
    //     content: "";
    //     background: url(/cms/images/logo-edit.png) no-repeat;
    //     top: -2px;
    //     width: 12px;
    //     height: 12px;
    //     display: inline-block;
    //     margin-right: 5px;
    //     background-size: 12px;
    // }
    &:hover{
        background-color: darken($color-button-cancel-bg, 5%);
        border: 1px solid $color-border-button-gray;
    }
}
.btn__delete{
    color: $color-main;
    position: relative;
    margin: 8px 8px 8px 19px;
    transition: all .3s;
    &:hover{
        svg{
            path{fill: $color-button-hover;transition: all .3s;}
        }
    }
}



.btn__disable{
    background-color: darken(#f7f7f7, 2%) !important;
    pointer-events: none !important;
    -moz-user-select: none !important;
    -webkit-user-select: none !important;
    -ms-user-select: none !important; 
    user-select: none !important;
    -o-user-select: none !important;
    color: #fff !important;
    &:hover{
        background-color: #f7f7f7;
    }
}

.btn__add{
    height: 26px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    background-color: $color-button-bg;
    color: $color-button-text;
    font-family: $font-second-bold;
    font-size: 11px;
    text-transform: uppercase;
    transition: all .3s;
    letter-spacing: 1px;
    border: 1px solid transparent;
    &:hover{
        background: darken($color-button-bg, 7%);
    }
}
.btn__add__cancel{
    @extend .btn__add;

    background-color: $color-button-cancel-bg;
    color: $color-button-cancel-text;
    &:hover{
        background: darken($color-button-cancel-bg, 5%);
    }
}

/* BUTTON WIZARD */
.btn__wizard__next{
    @extend .btn__form;
}
.btn__wizard__prev{
    @extend .btn__form;

    padding: 0 !important;
    width: 26px;
    height: 29px;
    margin-right: 10px;
    background-color: #f0f0f0 !important;
    position: relative;
    &:before{
        content: "<";
        position: absolute;
        left: 0;
        right: 0;
        top: -1px;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        color: $color-text-small;
    }
    &:hover{
        border: 1px solid #f0f0f0;
    }
}
/* END BUTTON WIZARD */

.btn__form{
	background: $color-button-bg;
	border: 1px solid $color-button-bg;
	padding: 0 17px;
    height: 25px;
	color: $color-button-text;
	font-size: 11px;
	font-family: $font-form-bottom;
    text-transform: uppercase;
    letter-spacing: 0.58px;
    transition: all .3s;
    display: flex;
    align-items: center;
	&:hover{
        background: darken($color-button-bg, 10%);
	}
}
.btn__form__reset{
    background: $color-bg-hover;
    border: 1px solid #e6e7ea;
    padding: 0 20px;
    height: 25px;
    color: $color-text-small;
    font-family: $font-form-bottom;
    text-transform: uppercase;
    letter-spacing: 0.58px;
    // border-radius: 5px;
    font-size: 11px;
    display: flex;
    align-items: center;
    &:hover{
        background: #E8E8E8;
    }
}
.btn__form__create{
    @extend .btn__form__reset;
    color: #ffffff;
    background: $color-button-bg;
    margin-left: 10px;
    &:hover{
        background: darken($color-button-bg, 5%);
    }
}


.btn__close{
    border: 1px solid #dddee3;
    padding: 5px 13px 5px 25px;
    color: $color-main;
    border-radius: 3px;
    font-size: 13px;
    position: relative;
    transition: all .3s;
    &:before{
        content: "";
        // background: url(/cms/images/logo-close.png) no-repeat;
        top: 1px;
        width: 16px;
        height: 16px;
        position: absolute;
        left: 7px;
        transition: all .1s;
        bottom: 0;
        margin: auto;
    }
    @media only screen and (-webkit-min-device-pixel-ratio: 2),(min-resolution: 192dpi) {
        &:before{
            content: "";
            background: url(/cms/images/logo-close@2x.png) no-repeat;
            background-size: 14px 14px;
        }
    }
}

.switch-input:checked ~ .card--title{
    color: $color-text-hover;
}
// button switch style
.switch{
    position: relative;
    display: block;
    vertical-align: top;
    width: 79px;
    height: 24px;
    margin:0;
    border-radius: 18px;
    // box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    // button switch mini version
    &.switch--mini{
        width: 20px;
        height: 20px;
        .switch-handle {
            top: 0;
            right: 0;
            width: 50%;
            height: 100%;
            border-radius: 0;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
        }
        .switch-label {
            &:before{
                content: "";
            }
            &:after{
                content: "";
            }
        }
        .switch-input:checked ~ .switch-handle {
            right: 48%;
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
        }
        .switch-handle {
            box-shadow: 1px 1px 5px rgba(0, 0, 0, 0);
        }
        .switch-input:checked ~ .switch-handle {
            box-shadow: -1px 1px 5px rgba(0, 0, 0, 0);
        }
    }
    .switch-input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }
    .switch-label {
        position: relative;
        display: block;
        height: inherit;
        font-size: 12px;
        background: $semilightgrey;
        border-radius: inherit;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
        &:before{
            content: attr(data-off);
            left: 12px;
            color: #FFFFFF;
        }
        &:after{
            content: attr(data-on);
            right: 18px;
            color: #FFFFFF;
            opacity: 0;
        }
    }
    .switch-label:before, .switch-label:after {
        position: absolute;
        top: 50%;
        margin-top: -.5em;
        line-height: 1;
        -webkit-transition: inherit;
        -moz-transition: inherit;
        -o-transition: inherit;
        transition: inherit;
    }
    .switch-input:checked ~ .switch-label {
        background: $limegreen;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
    }
    .switch-input:checked ~ .switch-label:before {
        opacity: 0;
    }
    .switch-input:checked ~ .switch-label:after {
        opacity: 1;
    }
    .switch-handle {
        position: absolute;
        top: 3px;
        right: 4px;
        width: 17px;
        height: 17px;
        background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0);
        background-image: -webkit-linear-gradient(top, #FFFFFF 40%, #f0f0f0);
        border-radius: 100%;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    }
    .switch-input:checked ~ .switch-handle {
        right: 75%;
        box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
    }

    .switch-label, .switch-handle {
        transition: All 0.3s ease;
        -webkit-transition: All 0.3s ease;
        -moz-transition: All 0.3s ease;
        -o-transition: All 0.3s ease;
    }
}


.add__link{
    font-size: 12px;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 5px;
    text-decoration: underline;
    &:hover{
        text-decoration: none;
    }
}
.link__action{
    font-size: 12px;
    color: $color-text-main;
    position: relative;
    text-decoration: underline;
    &:hover{
        text-decoration: none;
    }
}


/* STYLE ADD MORE ITEM FORM */
.btn__add__more {
    width: 23px;
    height: 23px;
    position: relative;
    display: flex;
    border-radius: 100%;
    background-color: $color-button-bg;
    &:after{
        content: "+";
        position: relative;
        top: -4px;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        font-size: 22px;
        display: inline-block;
        font-weight: bolder;
        color: #fff;
    }
    &:hover{
        background-color: darken($color-button-bg, 5%);
    }

}
.btn__remove__more {
    width: 22px;
    height: 22px;
    position: relative;
    display: flex;
    border-radius: 100%;
    background-color: $color-button-cancel-bg;
    border: 1px solid darken($color-button-cancel-bg, 5%);
    &:after{
        content: "-";
        position: relative;
        top: -7px;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        font-size: 22px;
        display: inline-block;
        font-weight: bolder;
        color: $color-button-cancel-text;
    }
    &:hover{
        background-color: darken($color-button-cancel-bg, 5%);
    }

}

/* END STYLE ADD MORE ITEM FORM */



/* STYLE BUTTON CARD ADDON AYANA ROMANTIC DINING */
.btn__addon__add{
    width: 25px;
    height: 25px;
    background: $color-button-bg;
    display: flex;
    border-radius: 2px;
    transition: all .2s;
    &:after{
      content: '+';
      position: relative;
      top: -1px;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      font-size: 20px;
      font-weight: bolder;
      color: #fff;
  }
  &:hover{
      background: darken($color-button-bg, 5%);
  }
}
.btn__addon__see{
    width: 24px;
    height: 24px;
    display: flex;
    border-radius: 2px;
    transition: all .2s;
    border: 1px solid rgba(159,191,203,.25);
    position: relative;
    &.expanded{
        i{
            transform: rotate(180deg);
        }
    }
    i{
      background: url(/cms/images/sidebar/ico-accordion--arrow.svg) no-repeat;
      background-size: 9px 5px;
      width: 9px;
      height: 5px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      transition: all .5s;
  }
  &:after{
      content: '';
      position: relative;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
  }
  &:hover{
      background: darken(#fff, 5%);
  }
}
/* END STYLE BUTTON CARD ADDON AYANA ROMANTIC DINING $font-form-bottom


/* BUTTON UPLOAD WITH PROGRESBAR */
.btn__upload__progress{
    position: relative;
    overflow: hidden;
    width: 99px;
    height: 27px;
    border: 0;
    margin: 10px auto;
    padding: 0;
    &.btn__disable{
        label{
            color: darken(#f7f7f7, 10%);
        } 
    }
    label {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    input[type="file"]{
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        padding: 0;
        /* font-size: 20px; */
        cursor: pointer;
        opacity: 0;
        z-index: -1;
    }
    progress[value] {
      /* Reset the default appearance */
      -webkit-appearance: none;
      appearance: none;
    }
    progress[value]::-webkit-progress-bar {      
      border-radius: 2px;
      background-color: #ffffff;
    }
    progress[value]::-webkit-progress-value {      
      border-radius: 2px;
      background-color: #82D9F7;
    }
    progress{
        position: absolute;
        bottom: -2px;
        left: -1px;
        width: 101px;
        height: 4px;
    }
}
#upload--file{
    text-align: center;
    font-size: 10px;
    color: darken($color-text-main, 10%);
    word-wrap: break-word;
}


/* STYLE BUTTON REMOVE ITEM FROM LIST WITH FADE IN TEXT REMOVE */
.button__remove__item{
    font-size: 25px;
    color: $color-button-remove-with-text;
    transition: all .2s ease-out;
    &:before{
        content: 'Remove';
        text-transform: uppercase;
        font-family: $color-button-remove-with-text-hover;
        font-size: 12px;
        letter-spacing: 0.5px;
        position: relative;
        top: -5px;
        opacity: 0;
        left: 25px;
        transition: all .2s ease-out;
        
    }
    &:hover{
        color: $color-button-remove-with-text-hover;
        &:before{
            opacity: 1;
            left: -12px;
        }
    }
}
/* END STYLE BUTTON REMOVE ITEM FROM LIST WITH FADE IN TEXT REMOVE */

/* BUTTON TOGGLE MORE IN CARD STYLE */
.btn__arrow__more {
    width: 28px;
    height: 28px;
    border: 1px solid #F5F6F8;
}
/* END BUTTON TOGGLE MORE IN CARD STYLE */

/* BUTTON FOR IMG SLIDER MULTIPLE FORM SHOW */
.btn__action__list{
    position: relative;
    margin: 8px 8px 8px 13px;
    transition: all .3s;
    &:hover{
        svg{
            path{fill: $color-button-hover;transition: all .3s;}
        }
    }
}
/* END BUTTON FOR IMG SLIDER MULTIPLE FORM SHOW */