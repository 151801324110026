.transactions__page{
	.table__style{
		td{
			vertical-align: top;
		}
		th{
			&.title__invoice{width: 5%;};
			&.title__email{width: 20%;};
			&.title__payment__method{width: 13%;};
			&.title__gift__card__notes{width: 27%;};
			&.title__payment__status{width: 11%;};
			&.title__process__date{width: 15%;};
			&.title__action{width: 7%;};
		}
		.process__date{
			span{
				display: block;
				letter-spacing: 0.5px;
				line-height: 18px;
			}
		}
		.email{
			word-break: break-word;
		}
		.payment__status{
			display: flex;
			justify-content: center;
			.status__success{
				background: $limegreen;
				border-radius: 22px;
				padding: 5px 0;
				color: #fff;
				text-align: center;
				width: 100px;
				text-transform: uppercase;
				letter-spacing: 0.58px;
			}
			.status__waiting{
				@extend .status__success;
				background: $semilightgrey;
			}
			.status__expired{
				@extend .status__success;
				background: transparent;
				color: $grey;
			}
		}
	}


	.popup__wrapper{
		display: block;
		overflow: auto;
		.popup__layer{
			margin: 5% auto;
		}
		.popup__layer--content{
			font-size: 14px;
			.content--main{
				.table__order__info{	
					.table__row {
						display: flex;
					}
					.table__order__no {
						width: 7%;
					}
					.table__order__product {
						width: 50%;
					}
					.table__order__qty {
						width: 20%;
						text-align: center;
					}
					.table__order__total {
						width: 23%;
						text-align: center;
					}
					.table__order__header{
						padding: 7px 0;
						border-top: 1px solid #E1E1E1;
						border-bottom: 1px solid #E1E1E1;
						.table__column{
							text-align: center;
							&.table__order__no{
								text-align: left;
							}
						}
					}
					.table__order__content{
						.table__row{
							padding: 15px 0;
							align-items: center;
							&.item__list{
								border-bottom: 1px solid #E1E1E1;
							}
						}
						.table__order__product{
							display: flex;
							align-items: center;
							.img__thumb {
								width: 70px;
								height: 70px;
								margin-right: 20px;
								position: relative;
								overflow: hidden;
								img{
									position: absolute;
									width: 100%;
									height: auto;
									top: 0;
									bottom: 0;
									margin: auto;
								}
							}
							.img__desc {
								display: block;
								width: calc(100% - 90px);
							}
							.img__cat {
								font-size: 12px;
							}
						}
						.table__price{
							.table__row{
								justify-content: flex-end;
								&.total__price{
									padding: 10px 0;
									p{
										margin: 10px 0;
									}
								}
								&.grand__total{
									padding: 0;
									background: #F3F3F3;
								}
							}
						}
					}
				}
			}
		}
	}

	
}

.filter__content{
	.fadeIn-transition{
		transition: all .3s ease-in;
	}
	.fadeIn-enter{
		opacity: 0;
	}
	.fadeIn-leave{
		display: none;
	}
}