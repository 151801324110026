/*  UPLOAD STYLE */
.upload__button{
    width: 90px;
    height: 90px;
    border: 1px solid lighten($color-border-form, 20%);
    position: relative;
    background: url(/cms/images/ico-upload.svg) 65% 65% no-repeat;
    cursor: pointer;
    margin: 5px 0 0 0;
    transition: all .2s;
    &:hover{
        box-shadow: inset 0 0 10px darken($color-bg-hover, 15%);
    }
}
.upload__img{
    width: 40px;
    height: 40px;
    overflow: hidden;
    position: relative;
    border: 1px solid #e6e8ec;
    .upload__img__input{
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    .upload__img__label{
        width: inherit;
        height: inherit;
        position: relative;
        background: url(/cms/images/ico-upload.svg) 65% 65% no-repeat;
        cursor: pointer;
        transition: all .2s;
        &:hover{
            box-shadow: inset 0 0 10px #c7ccd5;
        }
    }
    .upload__img__preview{
        height: 100%;
        width: auto;
        position: absolute;
    }
    .upload__img__remove{
        position: absolute;
        background: url(/cms/images/ico-camera.svg) 65% 75% no-repeat;
        background-size: 12px;
        width: 14px;
        height: 14px;
        color: #fff;
        font-size: 10px;
        display: flex;
        justify-content: center;
        right: 1px;
        bottom: 1px;
        border-radius: 100%;
        background-color: $color-button-upload;
        border: 1px solid $color-button-upload;
        &:hover{
            background-color: darken($color-button-upload, 10%);
        }
    }
}
.upload__tip{
    text-align: left;
    margin: 0 10px;
    width: 300px;
    position: absolute;
    font-size: 9px;
    display: flex;
    align-items: center;
    background: #f6f6f6;
    padding: 7px;
    border: 1px solid #f0f0f0;
    color: #505050;
    left: 54px;
    top: 26px;
    box-sizing: border-box;
    b{
        color: #9FA197;
        font-weight: normal;
    }
}
/* END */


#toggle-form-photo-uploader-content{
    .form__photo__uploader{
        max-width: 670px;
    }
}

.form__photo__uploader {
    background-color: #F6F8F9;
    padding: 10px;
    small {
        font-size: 11px;
        span{
            color: $color-text-main;
        }
    }
    &.single__image{
        .form__photo__uploader__li{
            width: initial !important;
            &:first-child{
                &:before{
                    left: 45% !important;
                }
            }
            .form__photo__group{
                .upload__img{
                    width: 80px !important;
                    height: 80px !important;
                }
            }
        }
        .form__photo__placeholder{
            width: 102px;
            height: 125px;
        }
    }
    .form__photo__uploader__ul {
        margin: 10px 0;
        display: flex;
        position: relative;
        .form__photo__uploader__li {
            width: 135px;
            padding: 10px;
            box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.10), 0px 0px 2px 0px rgba(0,0,0,0.16);
            background-color: #fff;
            margin-right: 10px;
            position: relative !important;
            top: 0px !important;
            &:first-child{
                &:before{
                    content: "";
                    background: url(/cms/images/ico-tip.svg) no-repeat;
                    top: -7px;
                    left: 30%;
                    position: absolute;
                    width: 15px;
                    height: 15px;
                    background-size: 22px;
                    transform: rotate(135deg);
                    z-index: 0;
                }
            }
            &:last-child{
                margin-right: 0px;
            }
            .form__photo__handle {
                position: relative;
                cursor: move;
                display: inline-block;
                svg{
                    transform: rotate(90deg);
                    margin-left: 5px;
                }
            }
            .form__photo__group {
                display: flex;
                justify-content: space-between;
                .upload__img {
                    width: 60px;
                    height: 60px;
                    &:hover{
                        .upload__img__show__preview{
                            opacity: 1;
                            visibility: visible;
                        }
                        .upload__img__remove{
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                    .upload__img__label{
                        background: url(/cms/images/ico-upload.svg) center no-repeat;
                    }
                    .upload__img__show__preview{
                        opacity: 0;
                        visibility: hidden;
                        position: absolute;
                        background: url(/cms/images/ico-see-preview.svg) center no-repeat;
                        background-size: 11px;
                        width: 12px;
                        height: 12px;
                        color: #fff;
                        font-size: 10px;
                        display: -ms-flexbox;
                        display: flex;
                        -ms-flex-pack: center;
                        justify-content: center;
                        right: 0;
                        bottom: 1px;
                        left: 0;
                        margin: auto;
                        border-radius: 100%;
                        background-color: $color-button-upload-preview;
                        border: 1px solid $color-button-upload-preview;
                        transition: all .3s;
                        &:hover{
                            background-color: darken($color-button-hover, 10%);
                        }
                    }
                    .upload__img__remove{
                        opacity: 0;
                        visibility: hidden;
                        background: none;
                        background-color: #DE6060;
                        border: 1px solid #DE6060;
                        transition: all .3s;
                        &:hover{
                            background-color: darken(#DE6060, 10%);
                        }
                        &:before{
                            content:'';
                        }
                    }
                }
                .form__photo__title {
                    display: block;
                    font-size: 10px;
                    text-align: center;
                    border: 1px solid #e6e8ec;
                    margin-top: 3px;
                    padding: 2px 0;
                }
            }
            .form__photo__remove {
                position: absolute;
                top: -7px;
                right: -7px;
                width: 16px;
                height: 16px;
                font-size: 13px;
                border-radius: 100%;
                text-align: center;
                // line-height: 13px;
                color: #fff;
                background-color: #DE6060;
                &:hover{
                    background-color: darken(#DE6060, 10%);
                }
            }
            .form__photo__remove__server{
                position: absolute;
                top: -7px;
                right: -7px;
                width: 16px;
                height: 16px;
                font-size: 13px;
                border-radius: 100%;
                text-align: center;
                // line-height: 13px;
                color: #fff;
                background-color: #DE6060;
                &:hover{
                    background-color: darken(#DE6060, 10%);
                }
            }
        }
    }
    .form__photo__placeholder{
        width: 135px;
        height: 128px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px dashed;
        border-radius: 5px;
        flex-direction: column;
        margin-left: 10px;
        &:hover{
            i{
                top: 0px;
            }
            span{
                bottom: -15px;
                opacity: 1;
            }
        }
        i{
            font-style: normal;
            font-size: 38px;
            display: block;
            line-height: 0;
            position: relative;
            top: 10px;
            transition: all .4s ease-in-out;
        }
        span{
            font-size: 15px;
            display: block;
            position: relative;
            bottom: 0px;
            opacity: 0;
            transition: all .4s ease-in-out;
        }
    }
}

.img__preview__overlay{
    position: fixed;
    z-index: 99;
    display: none;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0;
    background-color: rgba(0, 0, 0, .5);
}
.img__preview__popup {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0;
    position: absolute;
    .img__preview__popup__wrapper {
        position: absolute;
        width: 600px;
        height: auto;
        padding: 10px;
        background-color: #fff;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1), 0px 0px 2px 0px rgba(0, 0, 0, 0.16);
        .img__preview__big__close{
            position: absolute;
            background-size: 12px;
            width: 24px;
            height: 24px;
            color: #fff;
            font-size: 18px;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-pack: center;
            justify-content: center;
            right: -11px;
            top: -11px;
            border-radius: 100%;
            background-color: #DE6060;
            border: 1px solid #DE6060;
            &:hover{
                background-color: darken(#DE6060, 10%);
            }
        }
        img.upload__img__preview__big{
            width: auto;
            max-width: 100%;
            height: auto;
            display: block;
            line-height: 0;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            /* padding: 40px 0; */
            margin: 0 auto;
        }
    }
}
// Add Class when popup
.popup__upload__preview__container{
    .sidebar__wrapper{
        z-index: 10;
    }
    .main__wrapper__header{
        position: relative;
        z-index: 0;
    }
    .container__main{
        position: relative;
        z-index: 11;
    }
}