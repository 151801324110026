.main__wrapper__header{
    display: flex;
    height: 40px;
    width: 100%;
    .main__wrapper__header__content{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: $color-header-background;
        border-bottom: 1px solid #f4f2ef;;
        position: relative;
        z-index: 2;
        // .header__title{
        //     position: relative;
        //     left: 30px;
        //     color: $darkblue;
        //     letter-spacing: 1.5px;
        // }
        .header__status__dropdown{
            position: relative;
            right: 25px;
            width: auto;
            font-size: 12px;
        }
        .header__title__dropdown{
            display: flex;
            align-items: center;
            .toggle__sidebar{
                height: 40px;
                width: 40px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition: all .3s;
                border-right: 1px solid #f4f2ef;;
                .bar{
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    transition: all .3s;
                    width: 50%;
                    .bar-1{
                        height: 2px;
                        display: block;
                        background-color: $color-header-toogle-bar;
                        width: 10px;
                        position: relative;
                        top: -3px;
                        transition: all .3s;
                        &:before{
                            content: "";
                            width: 0; 
                            height: 0; 
                            border-top: 2px solid transparent;
                            border-bottom: 2px solid transparent; 
                            border-right: 4px solid $color-header-toogle-bar;
                            position: absolute;
                            left: -4px;
                            top: -1px;
                        }
                    }
                    .bar-2{
                        height: 2px;
                        display: block;
                        background-color: $color-header-toogle-bar;
                        width: 7px;
                        position: relative;
                        transition: all .3s;
                    }
                    .bar-3{
                        height: 2px;
                        display: block;
                        background-color: $color-header-toogle-bar;
                        width: 14px;
                        position: relative;
                        bottom: -3px;
                        transition: all .3s;
                    }
                }
                &:hover{
                    background-color: lighten($color-header-toogle-hover, 10%);
                }
                &.animate__icon{
                    .bar{
                        .bar-1{
                            width: 20px;
                            &:before{
                                content: "";
                                border-top: 0px solid transparent;
                                border-bottom: 0px solid transparent; 
                                border-right: 0px solid $color-header-toogle-bar;
                            }
                        }
                        .bar-2{
                            width: 20px;
                        }
                        .bar-3{
                            width: 20px;
                        }
                    }
                }
            }
        }
    }
}

/* HEADER SELECTION DROPDOWN SELECT */
.dropdown__select__list{
    margin-left: 25px;
    position: relative;
    &.open{
        .dropdown__content{
            // show
            opacity: 1;
            visibility: visible;
            z-index: 99;
            transform: translateY(0px);
            transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
    }
    .display__name{
        font-family: $font-header-title;
        color: $color-header-title;
        margin: 0;
        line-height: 40px;
        height: auto;
        font-size: 16px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        /* display: block; */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        transition: all .2s;
        &:hover{
            color: darken($color-header-title, 10%);
        }
    }
    .dropdown__content{
        position: absolute;
        border-radius: 5px;
        border: 1px solid $color-header-dropdown-border-content;
        margin: 1px 0px;
        background: #FFFFFF;
        width: 250px;

        // hide
        opacity: 0;
        visibility: hidden;
        z-index: -1;
        transform: translateY(-20px);
        &:before{
            content: "";
            position: absolute;
            top: -9px;
            left: 12px;
            width: 0px;
            height: 0px;
            margin-top: 3px;
            border-bottom: 6px solid $color-header-dropdown-border-content;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
        }
        &:after{
            content: "";
            position: absolute;
            top: -9px;
            left: 12px;
            width: 0px;
            height: 0px;
            margin-top: 4px;
            border-bottom: 6px solid white;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
        }
        li{
            border-bottom: 1px solid rgba(155, 155, 155, 0.2);
            .dropdown__content__link{
                font-size: 13px;
                padding: 5px 15px;
                display: block;
                color: $color-header-dropdown-text;
                &:hover{
                    color: $color-header-dropdown-text-hover;
                }
            }
        }
    }
}


/* Select Dropdown Status in Header */
.dropdown__wrapper, .select__dropdown{
    /* Size & position */
    position: relative;
    padding: 3px 25px 3px 15px;
    background: #fff;
    border: 1px solid $color-header-dropdown-border;
    cursor: pointer;
    outline: none;
    // z-index: 3;
    &.active{
        // /*border-radius: 5px 5px 0 0;*/
        // background: $darkblue;
        // box-shadow: none;
        // border-bottom: none;
        // color: white;
        // border: 1px solid transparent;
        &:after{
            // border-color: #FFFFFF transparent;
        }
        .dropdown__search{
            border: 1px solid $color-header-dropdown-border;
            overflow-y: auto;
            margin: 0px -1px;
            opacity: 1;
            visibility: visible;
            z-index: 3;
            // box-shadow: 0 1px 19px rgba(0, 0, 0, 0.2);
        }
    }
    &:after{
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        top: 55%;
        right: 6px;
        margin-top: -3px;
        border-width: 4px 4px 0 4px;
        border-style: solid;
        border-color: $color-header-dropdown-border transparent;
    }
    .select--name{
        font-family: $font-header-status-dropdown;
        font-size: 13px;
        color: $color-header-dropdown-title;
    }
    &.active{
        .select--name{
            // color: #FFFFFF;
        }
    }
    .dropdown__search{
        /* Size & position */
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;

        /* Styles */
        margin: 0px -1px;
        background: #fff;
        border: 1px solid $color-header-dropdown-border;
        list-style: none;
        transform: translateY(10px);
        transition: .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

        /* Hiding */
        opacity: 0;
        max-height: 400px;
        overflow: hidden;
        visibility: hidden;
        z-index: -1;
        padding-left: 0px;
        li{
            a{
                display: block;
                text-decoration: none;
                color: $color-header-dropdown-text;
                padding: 0 14px;
                height: 31px;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #e6e8ea;
                // padding-left: 14px;
                &:hover{
                    background: #F0F0F0;
                    color: $color-header-dropdown-text-hover;
                }
            }
            i{
                margin-right: 5px;
                color: inherit;
                vertical-align: middle;
            }
        }
    }
}

.dropdown__wrapper .dropdown__search li:last-child a {
    border: none !important;
}

/* No CSS3 support: none */