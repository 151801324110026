.breadcrumb{
	display: block;
	.breadcrumb--a {
	    float: left;
	    display: inline-block;
	    transition: all .3s;
	    .breadcrumb--p {
		    margin: 0;
		    font-size: 11px;
		    letter-spacing: 0.5px;
		    text-transform: uppercase;
		}
		&:hover{
			// color: $color-header-breadcrumb-active;
		}
	}
	.breadcrumb--arrow {
	    float: left;
	    display: inline-block;
	    margin: 0 8px;
	    font-size: 10px;
	}
	&:after{
		clear: both;
	}
}