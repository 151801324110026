.page__event{
	.card__list{
		.card__list__layer {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding: 20px 0 15px 0;
			min-height: 75px;
			width: 31.3%;
			.card__list--top{
				padding: 0 4%;
			}
			.card__list--bot{
				padding: 0 4%;
				.card__list--bot--left{
					width: 70%;
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
				}
				.card__list--bot--right{
					width: 30%;
					position: relative;
					display: flex;
					justify-content: flex-end;
					align-items: flex-end;
				}
			}
			.card__list__desc{
				margin-top: 0;
				line-height: 18px;
				.end__date{
					color: #FF3030;
				}
			}
			.btn__info {
				background: #fff;
				width: 24px;
				height: 24px;
				border: 1px solid #E1E1E1;
				border-radius: 3px;
				transition: all .3s;
				i{
					position: absolute;
					width: inherit;
					height: inherit;
				}
				&:hover{
					background: darken(#fff, 5%);
				}
			}
			.promo__tag {
				position: absolute;
				right: 0;
				top: -4px;
				background: $limegreen;
				padding: 6px 13px 6px 19px;
				font-size: 13px;
				color: #fff;
				border-top-left-radius: 22px;
				border-bottom-left-radius: 22px;
				text-transform: uppercase;
			}
			.btn__end {
				font-family: $font-card-button;
				padding: 6px 12px;
				font-size: 12px;
				border: 1px solid $charcoal;
				letter-spacing: 0.5px;
				background: #fff;
				transition: all .3s;
				margin: 0 0 0 13px;
				&:hover{
					background: darken(#fff, 5%);
				}
			}
		}
	}

	.if__discount{
		width: 50px;
		margin: 0 18px 0 10px;
		display: flex;
		.new__form__input__field{
			text-align:center;
			padding: 0;
		}
		span{
			margin-left: 5px;
		}
	}

	#list-product-seasonal{
		.promo__price {
			width: 365px;
			transition: all .6s;
			// opacity: 0;
			// max-height: 0;
			// &.open{
			// 	opacity: 1;
			// 	max-height: 500px;
			// }
		}
		.news__list__detail__middle{
			justify-content: space-between;
		}
	}

	.fadeIn-transition{
		transition: all .3s ease-in;
	}
	.fadeIn-enter, .fadeIn-leave{
		opacity: 0;
	}

	.form__accordion{
		margin-top: 24px;
		.form__accordion__item{
			width: 365px;
			opacity: 0;
			transition: all 0.4s ease-out;
			&.show{
				opacity: 1;
			}
			&.open{
				.form__accordion__product__top{
					background: #FAFAFA;
					h5{
						font-weight: 600;
					}
				}
				&:last-child{
					.form__accordion__product__top{
						border-bottom: 1px solid #FAFAFA;
					}
				}
				.promo__price{
					background: #FAFAFA;
				}
			}
			.promo__price {
				background: #FFFFFF;
				transition: all .4s;
			}
			.form__accordion__product__top{
				padding: 8px;
				border: 1px solid $gainsboro;
				border-bottom: 1px solid transparent;
				background: #FFFFFF;
				transition: all .4s;
				h5{
					margin: 0;
					cursor: pointer;
					font-weight: 400;
					transition: all .4s;
					&:hover{
						text-decoration: underline;
					}
				}
			}
			.form__accordion__product__bottom{
				border: 1px solid $gainsboro;
				border-top: 1px solid #FAFAFA;
				border-bottom: 1px solid transparent;
			}
			&:last-child{
				.form__accordion__product__top{
					border-bottom: 1px solid $gainsboro;
				}
				.form__accordion__product__bottom{
					border-bottom: 1px solid $gainsboro;
				}
			}
			.form__accordion__product__bottom{
				display: none;
			}
		}
		.button__accordion__product{
			font-size: 20px;
			color: $verylightwhite;
			line-height: 0;
			&:hover{
				color: darken($verylightwhite, 15%);
			}
		}
	}

	.promo__price {
		padding: 10px 8px;
	}
	.promo__price__item {
		display: flex;
		align-items: center;
		position: relative;
		padding: 5px 0;
		.form--checkbox__wrapper{
			width: 105px;
			.checkbox--label{
				margin-bottom: 0;
			}
		}
	}
	.promo__initial__price {
		font-size: 12px;
		width: 95px;
		display: inline-block;
		color: $grey;
	}
	.promo__custom__price{
		position: absolute;
		right: 0;
		display: flex;
		align-items: center;
		span{
			text-transform: uppercase;
			font-size: 12px;
			left: 5px;
			position: absolute;
			color: $darkergrey;
		}
		.new__form__input__field{
			height: auto;
			text-align: right;
			width: 150px;
			padding: 5px 10px 5px 25px;
		}
	}
	.promo__discount__price{
		width: 110px;
		position: absolute;
		right: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		span{
			text-transform: uppercase;
			font-size: 12px;
			color: $darkergrey;
		}
		p{
			margin: 0;
			font-size: 13px;
		}
	}

	#select-promo-type{
		margin: 0;
	}
}

@media only screen 
and (max-width : 1500px) {
	.page__event{
		.card__list{
			.card__list__layer {
				width: 48%;
			}
		}
	}
}