.style__accordion{
	position: relative;
	margin-right: 5px;
	i{
		margin-left: 5px;
		svg{
			position: absolute;
			transform: rotate(180deg);
			transition: all .3s;
			circle{
				fill: #fff;
				transition: all .3s;
			}
			&:hover{
				circle{
					fill: darken(#fff, 3%);
				}
			}
		}
	}
	&.clicked{
		i{
			svg{
				transform: rotate(0deg);
			}
		}
	}
	&.inverse-color{
		i{
			svg{
				circle{
					fill: #D8D8D8;
				}
				path{
					fill: #fff;
				}
				&:hover{
					circle{
						fill: darken(#D8D8D8, 3%);
					}
				}
			}
		}
	}
}
// .style__accordion__content{
// 	display: none;
// }
.main__content__layer{
	.style__accordion{
		width: 18px;
		height: 18px;
		display: inline-block;
		vertical-align: sub;
		i{
			width: 18px;
			height: 18px;
			display: block;
			position: relative;
			margin:0;
		}
	}
	.style__accordion__inner{
		border-bottom: 1px solid #F0F0F0;
		.style__accordion__header{
			position: relative;
			z-index: 2;
			padding: 5px 15px;
			background-image: linear-gradient(-180deg, rgba(255,255,255,1.00) 0%, #FAFAFA 100%);
			box-shadow: 0 1px 2px 0 rgba(0,0,0,0.12);
			border-bottom: 1px solid #F0F0F0;
			h3{
				font-family: $font-main;
				font-size: 13px;
				color: $color-text-small;
			}
			.handle{
				padding: 0 15px 0 0;
			}
		}
		.style__accordion__content{
			position: relative;
			z-index: 1;
		}
	}
}

// FOR ACCORDION CARD STYLE
.content__top{
	.style__accordion{
		i{
			margin-right: 5px;
			svg{
				width: 18px;
				height: 18px;
			}
		}
	}
}


.form__group__row{
	.style__accordion{
		i{
			svg{
				width: 16px;
				height: 16px;
			}
		}
	}
}
// .content__bottom{	
// 	&.style__accordion__content{
// 		display: block;
// 	}
// }