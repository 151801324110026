/* SIDEBAR ACCORDION ICON */
/* .ico-pages{
    background: url(/cms/images/sidebar/ico-sidebar-pages.svg) no-repeat;
    width: 28px;
    height: 28px;
    display: inline-block;
}
.ico-seo{
    background: url(/cms/images/sidebar/ico-sidebar-seo.svg) no-repeat;
    width: 28px;
    height: 28px;
    display: inline-block;
}
.ico-account{
    background: url(/cms/images/sidebar/ico-sidebar-acounts.svg) no-repeat;
    width: 28px;
    height: 28px;
    display: inline-block;
} */

/* ICON SIDEBAR */
.ico-sidebar{
    /* width: 28px;
    height: 28px; */
    display: flex;
    svg{
        // height: 16px;
        width: auto;
    }
}
.open{
    .link{
        .ico-reservation{
            svg{
                path{
                    fill: inherit !important;
                    stroke: $color-sidebar-accordion-active !important;
                }
                polygon{
                    fill: $color-sidebar-accordion-active !important;
                }
            }
        }
    }
}
.ico-accordion-arrow{
    background: url(/cms/images/sidebar/ico-accordion--arrow.svg) no-repeat;
    width: 13px;
    height: 9px;
    left: auto !important;
    right: 12px !important;
    top: 25px !important;
}
/* END ICON SIDEBAR */

.ico-delete{
    display: flex;
}
.ico-date{
    background: url(/cms/images/ico-calendar.svg) no-repeat;
}
.ico-date-arrow{
    background: url(/cms/images/ico-arrow-double.svg) no-repeat;   
}

.icon-info{
    background: url(/cms/images/ico-info.svg) center no-repeat;  
}

.ico-arrow{
    background: url(/cms/images/sidebar/ico-accordion--arrow.svg) center no-repeat;
}