.main__wrapper{
	height: 100%;
}
.main__content{
	padding: 10px;
}
.container__main{
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 40px);
    backface-visibility:hidden;
    transform: translateZ(0);
}
.main__content__form__layer{
	background: #FFFFFF;
	box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
	margin: 10px 1% 20px 1%;
    position: relative;
    z-index: 1;
    color: $color-text-small2;
}

.main__content__layer{
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    margin: 15px 1% 15px 1%;
    .content__top{
        padding: 14px 15px;
        border-bottom: 1px solid #e9ebee;
        .content__btn {
            display: flex;
        }
    }
}

/* STYLE ANIMASI SORTABLE JQUERY-UI */
.sort-active{background: rgba(0,0,0,0.03);}
.sort-trans{ transition: 0.5s all cubic-bezier(0.165, 0.84, 0.44, 1);opacity: 0.9;}
.sort-el{transition: none !important; }
.plcehldr{display: none;}
.sort-ghost{opacity: 0.3;transition: all 0.5s ease-out;}
/*  END  */


/* STYLE NEWS LIST PER ROW */
.news__list{
    position: relative;
    .news__list__wrapper{
        border-top: 1px solid #e9ebee;
        background: #fff;
        position: relative !important;
        top: 0px !important;
        &:first-child{
            border-top: none !important;
            // padding-bottom: 10px;
        }
        .news__list__detail{
            padding: 15px;
            display: flex;
            .drag__control{
                display: flex;
                align-items: center;
                position: relative;
                padding: 0 15px 0 0;
                .order {
                    position: absolute;
                    top: 0;
                }
                .handle{
                    display: flex;
                    cursor: move;
                    color: darken($color-line, 8%);
                    &:hover{
                        color: darken($color-line, 10%);
                    }
                    i{
                        &:last-child{
                            position: relative;
                            right: -3px;
                        }
                    }
                }
            }
            .news__list__detail__left{
                width: 50px;
                height: 50px;
                position: relative;
                overflow: hidden;
                margin-right: 15px;
                img {
                    position: absolute;
                    width: 100%;
                    height: auto;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
            .news__list__detail__middle-right{
                display: flex;
                width: 100%;
                .news__list__detail__middle{
                    // padding: 0px 15px;
                    padding-right: 15px;
                    width: calc(100% - 150px);
                    display: flex;
                    align-items: center;
                    .news__list__desc{
                        
                        .news__desc{
                            position: relative;
                            top: 1px;
                            p{
                                display: block;
                                margin: 0;
                                white-space: nowrap;
                            }
                            .news__cat{
                                font-size: 12px;
                                color: lighten($color-text-small, 5%);
                                margin-bottom: 8px;
                                padding-right: 15px;
                                i{
                                    color: $color-text-big;
                                    font-style: normal;
                                }
                            }
                            .news__date{
                                font-size: 12px;
                                color: $color-text-small;
                            }
                            .pin-item{
                                color: #f37373;
                                i{
                                    svg{
                                        path{
                                            fill: #f37373;
                                        }
                                    }
                                }
                                &.pin-item-disable{
                                    color: #9b9b9b;
                                    i{
                                        svg{
                                            path{
                                                fill: #9b9b9b;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .news__name{
                            display: flex;
                            align-items: center;
                            .title__name{
                                font-size: 15px;
                                margin: 0;
                                color: $color-text-big;
                                font-weight: normal;
                                // &:hover{
                                //     color: $color-text-hover;
                                // }
                            }
                            // a{
                            //     position: relative;
                            //     transition: none;
                            //     &:hover{
                            //         text-decoration: underline;
                            //         &:after{
                            //             content: "";
                            //             background: url(/cms/images/ico-pencil.svg) no-repeat;
                            //             position: absolute;
                            //             width: 13px;
                            //             height: 12px;
                            //             top: 0;
                            //             bottom: 0;
                            //             margin: auto 0 auto 5px;
                            //             font-size: 15px;
                            //         }
                            //     }
                            // }
                        }
                    }
                }
                .news__list__detail__right{
                    display: flex;
                    align-items: center;
                    width: 150px;
                    justify-content: flex-end;
                    transition: .3s;                                
                    label{
                        font-size: 12px;
                        color: $darkgrey;
                    }
                    .application__list__received{
                        top: 7px;
                        position: relative;
                        b{
                            label{
                                margin: 0px;
                            }
                        }
                    }
                }
            }                        
        }
    }
    .ui-state-highlight{
        height: 121px;
        width: 100%;
        border: 1px dashed #505050;
    }
}
.news__list__no__desc{
    .news__list{
        .news__list__wrapper{
            .news__list__detail{
                padding: 22px 15px;
                .news__list__detail__middle-right{
                    .news__list__detail__middle{
                         .news__list__desc{
                             .news__name{
                                 .title__name{
                                    font-size: 17px;
                                 }
                             }
                         }
                    }
                }
            }
        }
    }
}


/* card layer */
ul.card__list{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    li.card__list__layer {
        background: #FFF;
        position: relative;
        width: 27.3%;
        padding: 20px 2%;
        text-align: left;
        margin: 10px 1% 10px 1%;
        box-shadow: 0px 2px 4px 0px rgba(32,45,77,0.20);
        .card__list--top{
            position: relative;
            display: flex;
        }
        .card__list--bot {
            display: flex;
        }
        .card__list__desc{
            position: relative;
            margin-top: 3px;
            .desc__name{
                font-family: $font-card-desc-name; 
            }
            p{
                 font-size: 12px;
                 margin: 0;
                 color: $color-text-small;
             }
             label{
                font-size: 12px;
                color: $color-card-desc-text;
                b{
                    font-size: 12px;
                    color: $color-card-desc-label;
                    font-weight: 100;
                }
            }
            .packages__desc__row{
                label{
                    letter-spacing: 1.5px;
                    color: lighten($color-text-small, 30%);
                    text-transform: uppercase;
                }
                p{
                    letter-spacing: 0.43px;
                    line-height: 24px;
                    color: $color-text-small;
                }
            }
        }
        .card__list__btn{
            position: relative;
            height: 27px;
            width: 24%;
            padding: 0;
            display: flex;
            justify-content: flex-end;
        }
        .card__list--title{
            font-family: $font-card-title;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 76%;
            position: relative;
            display: flex;
            align-items: center;
            .card--title{
                font-size: 18px;
                color: $color-card-title;
                position: relative;
                &:hover{
                    text-decoration: underline;
                    // &:after{
                    //     content:"\f040";
                    //     font-family: 'FontAwesome';
                    //     width: 17px;
                    //     height: 15px;
                    //     display: inline-block;
                    //     position: absolute;
                    //     top: 3px;
                    //     margin-left: 5px;
                    // }
                }
            }
        }
        .handle{
            display: inline-block;
            cursor: move;
            color: darken($color-line, 8%);
            margin-right: 10px;
            &:hover{
                color: darken($color-line, 10%);
            }
        }
    }
    &.card__list__drag{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        position: relative;
        li.card__list__layer {
            width: 31.3%;
            padding: 0;
            .card__list--top{
                // padding: 20px 4% 9px 4%;
                padding: 9px 2%;
                border-bottom: 1px solid darken($color-line, 5%);
            }
            .card__list--bot{
                // padding: 20px 4%;
                padding: 0;
            }
        }
    }
    &.card__list--table{
        li.card__list__layer {
            width: 31.3%;
            padding: 0;
            .card__list--top{
                padding: 20px 4% 15px 4%;
                .card__list--title{
                    .card--title{
                        margin-left: 10px;
                    }
                }
            }
            .card__list--bot{
                padding: 0;
            }
            .table--ul{
                display: table;
                margin: 0px;
                padding: 0px;
                width: 100%;
                .table--li{
                    min-height: 42px;
                    display: flex;
                    align-items: center;

                    .table--li--text {
                        padding-left: 37px;
                        font-size: 14px;
                        margin: 0;
                        color: $color-border-form;
                        background-color: $lightgrey;
                        display: flex;
                        width: 100%;
                        min-height: inherit;
                        align-items: center;
                    }
                    .table--child--ul{
                        display: table;
                        margin: 0px;
                        padding: 0px;
                        width: 100%;
                        position: relative;
                        .table--child--li{
                            display: flex;
                            width: 100%;
                            background: #fff;
                            .table--cell{
                                list-style: none;
                                display: block;
                                padding: 15px 0 10px 0;
                                font-size: 14px;
                                margin: 0;
                                color: $color-text-small;
                                font-family: 'avenir-book';
                                &:first-child{
                                    text-align: center;
                                    width: 8%;
                                }
                                &:nth-child(2){
                                    text-align: left;
                                    width: 55%;
                                }
                                &:nth-child(3){
                                    text-align: right;
                                    width: 27%;
                                    padding-right: 3px;
                                }
                                &:last-child{
                                    text-align: center;
                                    width: 10%;
                                }
                            }
                        }   
                        .ui-state-highlight{
                            background: transparent;
                            border: 1px dashed $color-border-form;
                            height: 42px;
                        }
                    }
                }
            }
        }
    }
    .order{
        position: absolute;
    }
}
.grid-item{
    width: 18%;
}

.container .ss-placeholder-child {
    background: transparent;
    border: 1px dashed blue;
}

/* ------------ */

/* style placeholder packery drag drop */
.packery-drop-placeholder {
  outline: 3px dashed hsla(0, 0%, 0%, 0.5);
  outline-offset: -6px;
  -webkit-transition: -webkit-transform 0.2s;
          transition: transform 0.2s;
}
/* END */

@media only screen 
and (max-width : 1500px) {
    ul.card__list{
        li.card__list__layer {
            width: 44%;
        }
        &.card__list__drag{
            li.card__list__layer {
                width: 48%;
                padding: 0;
            }
        }
        &.card__list--table{
            li.card__list__layer {
                width: 48%;
                padding: 0;
            }
        }
    }
}

@media only screen 
and (max-width : 1280px) {
    ul.card__list{
        li.card__list__layer {
            margin: 7px 1% 7px 1%;
        }
    }
}  

/* (1024px above)----------- */
@media only screen 
and (max-width : 1024px) {
    
    ul.card__list{
        li.card__list__layer {
            margin: 5px 1% 5px 1%;
            .card__list__desc{
                p{
                    font-size: 11px;
                }
            }
            .card__list--title{
                .card--title{
                    font-size: 15px;
                }
            }
        }
    }

}