[type="checkbox"]:not(:checked), [type="checkbox"]:checked {
	position: absolute;
	left: -9999px;
}


/* CK Editor set width */
.cke_editable {
    padding: 5px 15px;
}

input, textarea,select {
	max-width: 100%;
	background-color: #fff;
	color: $color-text-big;
	font-family: $font-main;
	padding: 5px;
	text-align: left;
	box-sizing: border-box;
	border: 1px solid $color-border-form;	
	font-size: 12px;	
	&:active,&:focus {
		outline:none;
	}
	option{
		font-size: 13px;
		color:#5b6581;
		border-top:0;
		border-right:0;
		border-left:0;
		border: 1px solid $color-border-form;
	}
	&:disabled{
		background: #FAFAFA;
	}
}
select{
	font-size: 12px !important;
	width: 100%;
	height: 32px;
	border: 1px solid $color-form-input-border;
	border-radius: 0;
	-webkit-appearance: none;  /*Removes default chrome and safari style*/
	-moz-appearance: none; /* Removes Default Firefox style*/
	background: url(/cms/images/arrow_dropdown.png) right 8px center  no-repeat;
	background-size: 8px 5px !important;
	text-indent: 0.01px; /* Removes default arrow from firefox*/
	text-overflow: ellipsis;  /*Removes default arrow from firefox*/
	cursor: pointer;
	margin-right: 20px;
	letter-spacing: 0;
	padding: 5px 10px;
	color: $color-text-big;
	&:after{
		content: "";
		border-color: #888 transparent transparent transparent;
		border-style: solid;
		border-width: 5px 4px 0 4px;
		height: 0;
		left: 50%;
		margin-left: -4px;
		margin-top: -2px;
		position: absolute;
		top: 50%;
		width: 0;
	}
	&::-ms-expand{display:none};
	option{
		font-size: 12px;
	}
}

select[disabled]{
	background: #E8E8E8 !important;
}
textarea{
	@extend .textarea500;
	resize: none;
}


/* style input with date icon */
.field--date{
	position: relative;
    display: flex;
    .new__form__input__field{
    	width: 100%;
    	margin-right: 0px;
    	color: $color-text-small;
    }
	.field--date__wrapper{
		position: absolute;
	    width: 35px;
	    height: 30px;
	    right: 10px;
	    background: $lightgrey;
	    border: 1px solid $color-border-form;
	    z-index: 0;
	    cursor: pointer;
	    right: 0px;
	    margin: 5px 0px !important;
	}
	&:hover{
		.field--date__wrapper{
			background: #ECECEC;
		}
	}
}
/* END */


/* form style */
.create__form__wrapper{
	.form--top{
		padding: 14px 15px 14px 15px;
		border-bottom: 1px solid $color-line;
		.form__title{	
			font-family: $font-form-top;
			color: $color-text-big;
			font-size: 21px;
			text-transform: capitalize;
		}
	}
	.form--mid{
		padding: 20px 15px 5px 15px;
	}
	.form--bot{
		padding: 15px 15px 15px 15px;
		border-top: 1px solid $color-line;
		.create__form__row{
			justify-content: flex-start;
			padding: 0 !important;
			.new__form__btn{
				margin-top: 0;
			}
		}
	}
	.create__form{
		display: flex;
		flex-direction: column;
		.create__form__row{
			display: flex;
			flex-wrap: wrap;
			padding: 0px 0 12px 0;
			position: relative;
			align-items: start;
			&.right-align{
				justify-content: flex-end;
			}
			.select2-container{
				width: 100% !important;
			}
			.input-icon{
				display: flex;
				position: relative;
				input{
					width: 100%;
				}
				.icon__wrapper{
					width: 30px;
					height: 30px;
					position: absolute;
					border: 1px solid #adb2bf;
					right: 0;
					cursor: pointer;
					transition: all .2s;
					i{
						width: 100%;
						height: 100%;
						position: absolute;
						background-position: center;
						background-size: 15px;
					}
					&:hover{
						background-color: darken($color-main, 5%);
					}
				}
				&.input-icon__time{
					.icon__wrapper{
						width: 16px;
						i{
							background-size: 20px;
						}
					}
				}
			}
		}
		.form__group__title{
			font-family: $font-form-group-title;
			font-size: 14px;
			text-transform: uppercase;
			letter-spacing: 1px;
			color: $color-text-form-group;
		}
	}
	.upload__wrapper{
		width: 100%;
	}
	.form__line{
		border: 1px solid transparent;
		border-bottom: 1px solid $color-line;
		margin: 0 0 15px 0;
	}
	
	.new__form__input__field{
		height: 32px;
		border: 1px solid $color-form-input-border;
		font-size: 12px;
		padding: 5px 10px;
		letter-spacing: 0;
	}
}

/* GLOBAL INPUT, BUTTON, ERROR MESSAGE (FORM TOGGLE & POPUP) */
.width-auto{
	width: auto !important;
}
.width100{
	width: 100% !important;
	margin-right: 0 !important;
}
.group__input__field{
	.new__form__field{
		width: 200px;
	}
}
.new__form__field__single {
	display: flex;
	align-items: flex-start;
	.label__field{
		width: 150px;
		position: relative;
		top: 5px;
	}
    label{
		display: inline-block;
		color: $color-form-text-label;
		font-size: 12px;
		position: relative;
		line-height: 21px;
	}
    button{
    	height: 32px;
    	position: relative;
    	left: -1px;
    	top: -1px;
    	border: 1px solid transparent;
    }
}
.new__form__field{
	display: flex;
	flex-direction: column;
	width: 250px;
	margin-right: 20px;
	position: relative;
	label{
		display: block;
		color: $color-form-text-label;
		font-size: 11px;
		position: relative;
		line-height: 21px;
		margin-bottom: 3px;
	}
	&.ckeditor{
		width: auto;
	}
	/* INPUT FIELD WIDTH ICON IN LEFT */
	.field__icon{
		position: relative;
		.field__icon__img{
			position: absolute;
			width: 16px;
			height: 12px;
			top: 0;
			bottom: 0;
			margin: auto 3px auto 4px;
		}
		input{
			width: 100%;
			padding-left: 22px;
		}
	}
	/* END INPUT FIELD WIDTH ICON IN LEFT */
	&.upload__image{
		.form--error--message{
			text-align: left;
		}
	}
	.textarea500{
		width: 500px;
		height: 150px;
	}
}
.new__form__btn {
	display: flex;
	align-items: flex-start;
	font-size: 13px;
	margin-top: 15px;
}
.form--error--message{
	font-size: 12px;
	color: #DE6060;
	text-align: right;
	margin: 5px 0 0 0;
}
.left{
	text-align: left;
}
.pin-side-error{
	position: absolute;
	right: 0;
	margin: 2px 0 0 0px;
}
::-webkit-input-placeholder { /* WebKit, Blink, Edge */
	letter-spacing: 0;
	font-size: 11px;
	opacity: 0.5;
	color: $color-text-small-placeholder;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
	letter-spacing: 0;
	font-size: 11px;
	opacity: 0.5;
	color: $color-text-small-placeholder;
}
.field__video{
	width: 500px !important;
}

/* END */


// style input radio
.input--radio--ul{
	display: flex;
	li{
		position: relative;
		height: 32px;
		input[type=radio]{
			position: absolute;
			visibility: hidden;
		}
		label{
			display: block;
			position: relative;
			color: $color-radio-label;
			padding: 0px 0px 0px 25px;
			margin: 5px 25px 5px auto;
			z-index: 9;
			cursor: pointer;
			transition: all 0.25s linear;
		}
		.check{
			display: block;
			position: absolute;
			border: 1px solid darken($lightgrey, 15%);
			border-radius: 100%;
			height: 15px;
			width: 15px;
			top: 0;
			left: 0px;
			bottom: 0;
			margin: auto;
			z-index: 5;
			transition: border .25s linear;
			&:before{
				content: '';
				display: block;
				position: absolute;
				border-radius: 100%;
				height: 7px;
				width: 7px;
				top: 4px;
				left: 4px;
				margin: auto;
				transition: background 0.25s linear;
			}
		}

		input[type=radio]:checked ~ .check::before{
			background: $color-radio-dot;
		}
	}
}
//  END









/* style info success */
.info--alert--success{
    background: #53B4C4;
    border: 0px solid $lightgrey;
    box-shadow: 0px 1px 4px 0px rgba(32,45,77,0.20);
    font-family: 'lato-regular';
    padding: 15px 0px;
    margin: 10px 1% 10px 1%;
    transition: all .3s;
    .info--alert--success--mes{
        color: #ffffff;
        position: relative;
        margin-left: 55px;
        &:before{
            content: "";
            background: url(/cms/images/logo-checked-green.png) no-repeat;
            position: absolute;
            width: 28px;
            height: 28px;
            top: -7px;
            left: -35px;
        }
    }
}
/* END */



/* STYLE ADD MORE ITEM FORM */
.form__add__more__wrapper{
	.form__add__more{
		display: flex;
		align-items: center;
		.form__add__more__btn {
			margin-right: 10px;
		}
	}
	.custom__price{
		width: 217px;
		margin-right: 10px;
		input{
			width: inherit;
		}
	}
}
/* END STYLE ADD MORE ITEM FORM */


/* STYLE DRAG N DROP LIST IN FORM */
.form__drag{
	width: 100%;
	.form__drag__title{
		font-size: 14px;
		text-transform: uppercase;
		letter-spacing: 1.17px;
		color: $color-form-drag-title;
	}
	.news__list{
		background: #ffffff;
		.news__list__wrapper{
			opacity: 0;
			&.animate{
				opacity: 0;
				transition: all 0.4s ease-out;
			}
			&.show{
				opacity: 1;
			}
			.news__list__detail{
				padding: 10px 10px 10px 0;
				.news__list__detail__left{
					width: 40px;
					height: 40px;
					top: 0;
					bottom: 0;
					margin: auto 15px auto auto;
				}
				.news__list__detail__middle-right{
					.news__list__detail__middle{
						.news__list__desc{
							.news__name{
								.title__name{
									font-size: 14px;
								}
							}
						}
					}
				}
			}
		}
	}
}
/* END STYLE DRAG N DROP LIST IN FORM */