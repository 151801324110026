/* CHECKBOX PRIVILEGE ROLE MANAGER */
.checkbox--privilege{
	padding-left: 21px;
	.form--checkbox__wrapper {
		display: inline-block;
		width: 279px;
		padding: 0 21px 5px 0;
		vertical-align: top;
		.checkbox--input + .checkbox--label:before{
			content: '';
			transform: translateX(-21px);
			position: absolute;
			top: 4px;
		}
		.checkbox--input:checked + .checkbox--label:after{
			content: "";
			left: 1px;
			top: 3px;
			margin: 0;
			transform: translateX(-21px);
		}
	}
}


.new__form__field{
	width: 365px;
}

.btn__arrow__more {
    position: absolute;
    right: 0;
    bottom: 4px;
    transition: all .3s;
    i{
    	position: absolute;
    	width: inherit;
    	height: inherit;
    	transform: rotate(0deg);
    	transition: all .3s;
    }
    &:hover{
    	background: darken(#fff, 3%);
    }
    &.expanded{
    	i{
    		transform: rotate(180deg);
    	}
    }
}