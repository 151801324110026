.sidebar__wrapper{
	background: $color-sidebar;
    height: 100vh;
    width: 220px;
    z-index: 10;
    position: fixed;
}
.sidebar__wrapper__logo{
	display: flex;
	align-items: center;
	justify-content: center;
	border-right: 1px solid #f4f2ef;;
	border-bottom: 1px solid #f4f2ef;;
	height: 121px;
	a{
		width: 126px;
		// height: 84px;
	}
	img{
		position: relative;
		width: inherit;
		height: inherit;

	}
}
.sidebar__action__accordion{
	width: 100%;
	z-index: 3;
	position: relative;
	height: inherit;
}
.sidebar__accordion{
	width: 100%;
	max-width: 360px;
	margin: 0px auto;
	background: $color-sidebar;
	/* -webkit-border-radius: 4px; */
	-moz-border-radius: 4px;
	/* border-radius: 4px; */
	padding-left: 0px;
	height: calc(100vh - 121px);
	overflow-y: auto;
	overflow-x: hidden;
	.link{
		font-family: $font-sidebar;
		letter-spacing: 1px;
		cursor: pointer;
		display: flex;
		align-items: center;
		height: 59px;
		padding: 0 15px 0 50px;
		color: $color-sidebar-accordion-text-hover;
		font-size: 14px;
		border-bottom: 1px solid $color-sidebar-accordion-border;
		position: relative;
		-webkit-transition: all 0.4s ease;
		-o-transition: all 0.4s ease;
		transition: all 0.4s ease;
		text-transform: uppercase;
		&:hover{
			background: rgba(243, 242, 239, 0.2);
		}
		.ico-sidebar{
			svg{
				path{
					transition: all .3s;
				}
			}
		}
	}
	li{
		position: relative;
		&:before{
			content: "";
			height: 100%;
			width: 0px;
			background-color: $color-sidebar-accordion-active;
			position: absolute;
			left: 0;
			top: 0;
			transition: all .3s;
			z-index: 1;
		}
		&.open{
			&:before{
				content: "";
				height: 100%;
				width: 2px;
			}
			i{
				&.ico-accordion-arrow{
					-webkit-transform: rotate(180deg);
					-ms-transform: rotate(180deg);
					-o-transform: rotate(180deg);
					transform: rotate(180deg);
				}
			}
			.link{
				// border-bottom: 1px solid transparent;
				background-color: $color-sidebar-accordion-open;
				// &:before{
				// 	content: "";
				// 	width: 2px;
				// 	height: 25%;
				// 	background: #F4F2EF;
				// 	position: absolute;
				// 	left: 25px;
				// 	bottom: 0;
				// }
				.ico-sidebar{
					svg{
						path, ellipse{
							fill: $color-sidebar-accordion-active;
						}
					}
				}
			}
			.sidebar__accordion__submenu{
				display: block;
				background-color: $color-sidebar-accordion-open;
				li{
					&:last-child{
						border-bottom: 1px solid $color-sidebar-accordion-border;
					}
				}
			}
		}
		&:last-child{
			&.open{
				.sidebar__accordion__submenu{
					border-bottom: 1px solid transparent;
				}
			}
		}
		i{
			position: absolute;
			top: auto;
			left: 20px;
			font-size: 18px;
			color: #FFFFFF;
			-webkit-transition: all 0.4s ease;
			-o-transition: all 0.4s ease;
			transition: all 0.4s ease;
			&.fa-chevron-down{
				right: 12px;
				left: auto;
				font-size: 16px;
			}
		}
		.sidebar__accordion__submenu{
			display: none;
			background: $color-sidebar;
			font-size: 14px;
			padding-left: 0px;
			margin: 0px;
			border-bottom: 1px solid $color-sidebar-accordion-hover;
			position: relative;
			li{
				position: relative;
				&:last-child{
					&:after{
						content: "";
						width: 2px;
						height: 50%;
						background: #F4F2EF;
						position: absolute;
						left: 25px;
					}	
				}
				&:after{
					content: "";
					width: 2px;
					height: 100%;
					background: #F4F2EF;
					position: absolute;
					left: 25px;
					top: 0;
				}
				&:before{
					content: "";
					height: 2px;
					width: 12px;
					background: #F4F2EF;
					position: absolute;
					top: 0;
					bottom: 0;
					margin: auto;
					left: 25px;
				}
				&.active__accordion__item{
					&:before{
						content: "";
						background: $color-sidebar-accordion-active;
					}
					a{
						color: $color-sidebar-accordion-text-hover;
						// &:before{
						// 	content: "";
						// 	background: $color-sidebar-accordion-active;
						// }
					}
				}
			}
			a{
				display: block;
				text-decoration: none;
				color: $color-sidebar-accordion-text;
				padding: 12px;
				font-size: 13px;
				padding-left: 53px;
				-webkit-transition: all 0.25s ease;
				-o-transition: all 0.25s ease;
				transition: all 0.25s ease;
				position: relative;
				&:hover{
					color: $color-sidebar-accordion-text-hover;
				}
				// &:before{
				// 	content: "";
				// 	width: 6px;
				// 	height: 6px;
				// 	background: #F4F2EF;
				// 	position: absolute;
				// 	top: 0;
				// 	bottom: 0;
				// 	border-radius: 100%;
				// 	margin: auto;
				// 	left: 37px;
				// 	transition: all 0.25s ease;
				// }
			}
			&:before{
				/* content: "";
				width: 2px;
				height: 96%;
				background: #F4F2EF;
				position: absolute;
				left: 25px; */
			}
		}
	}
}

/* 
.active__accordion__item{
	a{
		background: $color-bg-hover !important;
		color: $color-main !important;
	}
} */


/** responsive **/
@media only screen 
and (max-width : 1520px) {
	.sidebar__wrapper__logo{
		a{
			width: 121px;
			// height: 79px;
		}
	}
}

/* (1024px above)----------- */
@media only screen 
and (max-width : 1024px) {
	.sidebar__wrapper{
	    width: 200px;
	}
	.sidebar__accordion{
		.link{
			font-size: 12px;
			padding: 0 15px 0 45px;
			height: 48px;
			}
	}
	.sidebar__accordion {
		li {
			.sidebar__accordion__submenu {
				font-size: 12px;
				a{
					font-size: 12px;
					padding: 9px 9px 9px 48px;
				}
			}
			i{
				top: auto;
			}
			.ico-accordion-arrow{
				top: 19px !important;
			}
		}
	}
}

/* (1360px above)----------- */
@media only screen 
and (min-width : 1360px) {  
}

/* (1440px above)----------- */
@media only screen 
and (min-width : 1440px) { 
}



@media only screen 
and (max-height : 800px) {
	.sidebar__wrapper{
	    width: 200px;
	}
	.sidebar__accordion{
		.link{
			font-size: 12px;
			padding: 0 15px 0 45px;
			height: 48px;
			}
	}
	.sidebar__accordion {
		li {
			.sidebar__accordion__submenu {
				font-size: 12px;
				a{
					font-size: 12px;
					padding: 9px 9px 9px 48px;
				}
			}
			i{
				top: auto;
			}
			.ico-accordion-arrow{
				top: 19px !important;
			}
		}
	}
}