/* CHECKBOX STYLE */
.width-auto{
	.checkbox--group{
		display: flex;
		.form--checkbox__wrapper{
			padding-right: 30px;
			.checkbox--label{

			}
				&:last-child{
					padding: 0;
				}
		}
	}
}
.checkbox--admin--group{
	.form--checkbox__wrapper{
		display: inline-block;
		width: 300px;
		margin-right: 30px;
		vertical-align: top;
		.checkbox--label{
			float: left;
		}
		.checkbox--input:checked + .checkbox--label:after{
			transform: translateX(-20px);
		}
	}
}
.pin-checkbox{
	left: 0;
	.checkbox--label{
		font-size: 10px !important;
	}
	.checkbox--input + .checkbox--label:before{
		width: 10px !important;
		height: 10px !important;
		margin-right: 5px !important;
	}
	.checkbox--input:checked + .checkbox--label:after{
		top: 1px !important;
		left: -2px !important;
		background-size: 14px 13px !important;
	}
}
.form--checkbox__wrapper{
	position: relative;
	z-index: 2;
    padding-left: 20px;
	.checkbox--input{
		display: inline-block;
		vertical-align: middle;
		margin: 5px;
		cursor: pointer;
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		-ms-appearance: none;
	}
	.checkbox--label{
		font-size: 12px;
		color: #4a4a4a;
		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none; 
		user-select: none;
		-o-user-select: none;
		cursor: pointer;
		transition: all .2s;
	}
	.checkbox--input + .checkbox--label:before{
		content: '';
		display: inline-block;
		vertical-align: sub;
		border: 1px solid #D5D5D5;
		width: 13px;
		height: 13px;
		// margin-right: 7px;
		text-align: center;
		border-radius: 2px;
		background: #ffffff;
		position: absolute;
		transform: translateX(-20px);
		top: 3px;
	}
	.checkbox--input:checked + .checkbox--label:after{
		position: absolute;
		content: "";
		// left: 1px;
		top: 0px;
		left: 2px;
		// top: -7px;
		// bottom: 0;
		margin: auto;
		background:url('/cms/images/ico-check.svg') top center no-repeat;
		background-size: 17px 16px;
		width: 17px;
		height: 16px;
	}
	.checkbox--input:checked + .checkbox--label:before{
		// border: 1px solid $color-checkbox-active;
	}
	/* DISABLED CHECKBOX STYLE */
	.checkbox--input:disabled:not(:checked) + .checkbox--label:before,
	.checkbox--input:disabled:checked + .checkbox--label:before {
		border-color: #bbb;
		background-color: #ddd;
	}
	.checkbox--input:disabled:checked + .checkbox--label:after {
		color: #999;
	}
	.checkbox--input:disabled + .checkbox--label {
		color: #aaa;
	}
}
/* END */


/* MOVE CHECK STYLE */
.moving--check{
	display: flex;
	width: 100%;
	.form--checkbox__wrapper {
	    display: flex;
	    align-items: center;
	    height: 35px;
	    margin-left: 10px;
	    label{
	    	transition: all .2s;
	    	&:hover{
	    		color: $color-text-hover;
	    	}
	    }
	}
}
.add__assessment__wrapper{
	max-height: 400px;
	overflow: auto;
	margin-bottom: 10px;
}
.moving--check--table{
	width: 230px;
	.new--table__header {
		font-size: 12px;
		color: #627f8f;
		padding: 10px;
		background: $lightgrey;
		border-bottom: 1px solid rgba(97, 127, 143, 0.3);
	}
	ul{
		min-height: 160px;
		overflow-y: auto;
		overflow-x: hidden;
		position: relative;
		background: $lightgrey;
		li{
			position: relative;
			animation: slideFadeIn .5s;
			.form--checkbox__wrapper{
				&:hover{
					&:after{
						cursor: pointer;
					}
				}
			}
			.handle{
				position: absolute;
				right:8px;
				top:0;
				bottom:0;
				margin: auto;
				width: 14px;
				height: 16px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				cursor: move;
				.handle-bar1{
					@extend .handle-bar2;

					top: -3px;
				}
				.handle-bar2{
					width: 14px;
					height: 2px;
					background-color: $color-text-small;
					display: block;
					position: relative;
				}
				.handle-bar3{
					@extend .handle-bar2;

					bottom: -3px;
				}
			}
		}
		.bg--placeholder{
			position: absolute;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			height: 100%;
			width: 100%;
			p{
				font-size: 13px;
				width: 80%;
				margin: 10px auto;
				color: rgba(98, 127, 143, 0.3);
			}
		}
	}
}
.moving--check--checkbox{
	width: calc(100% - 230px);
	padding: 0px 0px 0px 20px;
	.new--checkbox__header{
		font-size: 12px;
		color: #627f8f;
		display: flex;
		justify-content: space-between;
		padding: 9px 10px 8px 10px;
		border: 1px solid $lightgrey;
	}
	.test__list__checkbox{
		border: 1px solid $color-line;
		border-top: none;
		ul{
			min-height: 162px;
			li{
				animation: fadeIn .5s;
				display: inline-block;
				width: 220px;
			}
		}
	}
	.checkbox--label{
		color: $color-text-small;
	}
}

.priority, .priority-edit{
	display: none;
}

#error-test-list-st{
	position: relative;
	right: 20px;
}

.toggle-transition{
	transition: all .3s;
	overflow: hidden;
	height: 40px;
}

.toggle-enter, .toggle-leave{
	height: auto;
}

/* #ul-checked{
	li{
		transition: all .2s;
		&:hover{
			background-color: darken(#f3f4f6, 10%);
		}
	}
} */
/* END MOVE CHECK STYLE */


/* IS ADMIN MANAGER */
.is-admin{
    .checkbox--label{
        position: absolute;
        margin-top: 30px;
    }
    .form--checkbox__wrapper .checkbox--input:checked + .checkbox--label:after{
    	transform: translateX(-20px);
    }
}
/* END */


/* CHECKBOX GRID ADMIN */
.checkbox--grid__wrapper{
    width: 100%;
    display: inline-block;
    .checkbox--grid{
        padding: 5px 10px 5px 0;
        float: left;
        &:after{
        	content: "";
        	clear: both;
        }
    }
    .checkbox--grid--item{
        border: 1px solid $color-checkbox-grid-border;
        width: 240px;
        display: inline-block;
        .checkbox--grid--item--top{
            background: #F4F2EF;
            border-bottom: 1px solid $color-checkbox-grid-border;
            padding: 1px 3px 1px 24px;
            // font-weight: 800;   
        }
        .checkbox--grid--item--bottom{
            padding: 1px 3px 1px 24px;
            border-bottom: 1px solid $color-checkbox-grid-border;
            &:last-child{
            	border-bottom: 1px solid transparent;
            }
        }
        .form--checkbox__wrapper{
        	padding: 5px;
        	top: 0px;
        	display: inline-block;
        	vertical-align: top;
			.checkbox--input + .checkbox--label:before{
				content: '';
				transform: translateX(-21px);
				position: absolute;
				top: 10px;
			}
            .checkbox--input:checked + .checkbox--label:after{
            	content: "";
            	left: 5px;
            	top: 8px;
            	margin: 0;
            	transform: translateX(-21px);
            }
        }
    }
    .checkbox--label{
        color: $color-text-big;
    }
}
/* END */


/* CHECKBOX INSIDE FORM BOTTOM PART */
.form--bot{
  .form--checkbox__wrapper{
    .checkbox--input:checked + .checkbox--label:after{
      top: -1px;
    }
  }
}

.tags__wrapper{
	.tags__header{
		&.form--checkbox__wrapper .checkbox--input:checked + .checkbox--label:after{
			left: 12px;
			top: 14px;
		}
		&.form--checkbox__wrapper .checkbox--input + .checkbox--label:before{
			top: 17px;
		}
	}
}