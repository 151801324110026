/* JQUERY BLOCK UI HIDE BACKGROUD BOX MESSAGE */

.blockMsg{
  background-color: rgba(0, 0, 0, 0) !important;
  border: 0px solid transparent !important;
}

// Hide when page load
#loading-img{
  display: none;
}

.load {
  position: relative;
  margin: 50px auto;
  width: 100px;
  height: 70px;
}
.gear {
  position: absolute;
  z-index: -10;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 5s infinite;
          animation: spin 5s infinite;
}

.two {
  left: 40px;
  width: 80px;
  height: 80px;
  -webkit-animation: spin-reverse 5s infinite;
          animation: spin-reverse 5s infinite;
}

.three {
  top: 45px;
  left: -10px;
  width: 60px;
  height: 60px;
}

@-webkit-keyframes spin {
  50% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  50% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes spin-reverse {
  50% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@keyframes spin-reverse {
  50% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
.lil-circle {
  position: absolute;
  border-radius: 50%;
  box-shadow: inset 0 0 10px 2px gray, 0 0 50px white;
  width: 100px;
  height: 100px;
  opacity: .65;
}

.blur-circle {
  position: absolute;
  top: -19px;
  left: -19px;
}

.text {
  color: lightgray;
  font-size: 18px;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;          /* Likely future */   
}
.text span:first-child{
  margin-left: 2px;
}
.text span:nth-child(0) {
  -webkit-animation: fade 1.5s infinite;
  animation: fade 1.5s infinite;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.text span:nth-child(1) {
  -webkit-animation: fade 1.5s infinite;
  animation: fade 1.5s infinite;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

.text span:nth-child(2) {
  -webkit-animation: fade 1.5s infinite;
  animation: fade 1.5s infinite;
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}

.text span:nth-child(3) {
  -webkit-animation: fade 1.5s infinite;
  animation: fade 1.5s infinite;
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}

@-webkit-keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}