a{
	text-decoration: none;
	color: $color-text-small2;
}

ul { list-style-type: none; margin: 0; padding: 0;}


h1{
	font-family: $font-h1;
	color: $color-text-big;
	margin: 0px;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-size: 24px;
	font-weight: normal;
}
h2{
	font-family: $font-h2;
	color: $color-text-big;
	margin: 0;
	font-size: 20px;
	font-weight: normal;
}
.bold{
	font-weight: bold;
}