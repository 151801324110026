.form--wizard--tab{
	border-bottom: 1px solid $color-line;
	.wizard--tab--ul{
		display: flex;
		background-color: #fbfbfb;
		.wizard--tab--li{
			width: 100px;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			.wizard--tab--link{
				font-size: 12px;
				position: relative;
				&:hover{
					color: $color-button-hover;
				}
			}
			.empty--lang{
				&:after{
					content: "";
					background: url(/cms/images/ico-notif-lang.svg) no-repeat;
					width: 10px;
					height: 10px;
					position: absolute;
					right: -8px;
    				top: -4px;
				}
			}
			&.active__tab{
				a{
					color: $color-button-hover;
					transition: all .3s;
				}
			}
		}
	}
}

.content__tab{
	display: none !important;
	&.active__content{
		display: block !important;
		animation: fadeIn 2s;
	}
}
.content__tab2{
	display: none !important;
	&.active__content2{
		display: block !important;
		animation: fadeIn 2s;
	}
}
