.page__product{
	.form__accordion{
		.form__accordion__product__top{
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-right: 20px;
		}
		.form__accordion__product__bottom {
			padding: 6px 0 15px 0;
			margin-right: 20px;
		}
		.product__initial__price{
			position: relative;
			display: flex;
			align-items: center;
			span{
				text-transform: uppercase;
				font-size: 12px;
				left: 5px;
				position: absolute;
				color: $darkergrey;
			}
			.new__form__input__field{
				height: auto;
				text-align: right;
				width: 200px;
			}
		}
		.new__form__field{
			margin-left: 22px;
			margin-right: 0;
			label{
				color: $grey;
			}
		}
	}


	// ANIMASI
	.form__accordion__product__bottom {
		opacity: 0;
		max-height: 0;
		transition: all .5s;
		&.show{
			max-height: 300px;
			opacity: 1;
		}
	}
}

.product__checkbox{
	width: 180px;
	.form--checkbox__wrapper{
		padding: 0 0 5px 20px;
	}
}
.tags__wrapper{position:relative;}
// .tags__list{margin: 0 0 0 15px;}
.tags__header{
	padding: 15px 0 15px 30px !important;
	margin-bottom: 12px;
	background: darken(#fff, 3%);
	.form__group__title{
		color: inherit !important;
	}
}
// .bg__header__checkbox{
// 	background: darken(#fff, 3%);
// 	height: 50px;
// 	position: absolute;
// 	width: 100%;
// }
.product__checkbox__list{
	padding: 0 0 0 10px !important;
	.form--checkbox__wrapper .checkbox--input + .checkbox--label:before{
		width: 10px;
		height: 10px;
		top: 5px;
	}
	.form--checkbox__wrapper .checkbox--input:checked + .checkbox--label:after{
		background-size: 14px 13px;
		width: 14px;
		height: 13px;
		top: 2px;
	}
}