.text-primary{
	color: $color-timepicker-title-active !important;
}
.clockpicker-canvas, .clockpicker-dial{
	font-size: 14px;
}
.popover > .arrow, .popover > .arrow:after{
	display: none !important;
}
.clockpicker-svg{
	line{
		stroke: $color-timepicker-clock-line !important;
	}
	.clockpicker-canvas-bearing, .clockpicker-canvas-fg{
		fill: $color-timepicker-clock-bearing !important;
	}
	.clockpicker-canvas-bg{
		fill: $color-timepicker-clock-bg !important;
	}
}