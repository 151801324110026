@mixin spritebox($left, $top, $height, $width){
  background-image: url(/cms/images/sidebar/sprite.png);
  background-repeat: no-repeat;
  background-position: $left $top;
  height: $height;
  width: $width;
  display: inline-block;
}


.ico-applications{@include spritebox(-120px, -0px, 20px, 17px);} 
  

.ico-report{@include spritebox(-161px, -0px, 16px, 17px);} 
  

.ico-user{@include spritebox(-38px, -0px, 16px, 12px);} 
  

.ico-admin{@include spritebox(-0px, -0px, 15px, 15px);} 
  

.ico-library{@include spritebox(-79px, -0px, 12px, 18px);} 
  
