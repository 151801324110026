/* NOTIFICATION FLOAT STYLE */
.notification__wrapper {
    // position: fixed;
    // bottom: 98px;
    // left: 35px;
    // z-index: 99;
}
.notification__style {
    display: flex;
    background-color: $color-notification;
    width: 300px;
    align-items: center;
    padding: 25px;
    border-radius: 6px;
    position: relative;
    justify-content: space-between;
    color: #fff;
    font-size: 15px;
    .notification__messages {
        width: 80%;
        margin-right: 10px;
    }
    .notification__button{
        margin-right: 5px;
        width: 12px;
        height: 12px;
        position: relative;
        button{
            border: none;
            background-color: transparent;
            padding: 0;
            font-size: 22px;
            color: rgba(255,255,255,0.6);
            width: 12px;
            line-height: 0;
            position: absolute;
            height: 12px;
            &:hover{
                color: rgba(255,255,255,1);
            }
        }
    }
}
.notification__shadow{
    position: absolute;
    width: 300px;
    height: 100px;
    top: 0;
    &:after{
        content: '';
        display: block;
        position: absolute;
        left: 13%;
        bottom: 38px;
        width: 90%;
        max-width: 321px;
        height: 10px;
        background: transparent;
        border-radius: 100px/50px;
        box-shadow: 0 11px 40px rgba(0, 0, 0, 0.5);
        z-index: -10;
    }
}
.notifyjs-foo-base {
  opacity: 0.85;
  // width: 200px;
  // background: #F5F5F5;
  padding: 5px;
  border-radius: 10px;
}
.notifyjs-foo-error{
    .notification__style{
        background-color: #DE6060;
    }
}
/* END NOTIFICATION FLOAT STYLE */



/* NOTIFICATION IN POPUP CHANGE PASSWORD */
.info--notif--popup{
    display: block;
    margin: 0px auto;
    height: 32px;
    border-radius: 3px;
    transition: all .3s;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 0 10px;
    font-size: 14px;
    &:before{
        content: "";
        position: absolute;
        left: 11px;
        font-size: 28px;
        color: white;
        bottom: 0;
        top: 0;
        display: flex;
        align-items: center;
    }
    &.notif--success{
        background-color: #53B4C4;
        // &:before{
        //     content: "\f00c";
        //     font-family: 'FontAwesome';
        // }
    }
    &.notif--error{
        background-color: #DE6060;
        // &:before{
        //     content: "\f071";
        //     font-family: 'FontAwesome';
        // }
    }
}


@media only screen 
and (max-width : 1280px) {
    /* NOTIFICATION FLOAT STYLE */
    .notification__wrapper {
        bottom: 90px;
    }
    .notification__style {
        display: flex;
        background-color: $color-notification;
        width: 250px;
        align-items: center;
        padding: 20px;
        border-radius: 6px;
        position: relative;
        justify-content: space-between;
        color: #fff;
        font-size: 14px;
    }
    .notification__shadow{
        position: absolute;
        width: 260px;
        height: 95px;
    }
    /* END NOTIFICATION FLOAT STYLE */
}