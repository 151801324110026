table{
	border-collapse: collapse;
	// td,th {
	// 	font-size: 12px;
	// 	color: $color-text-small;
	// 	text-align: center;
	// }
}
// table.table__style, td, th {
//     border: 1px solid #F0F0F0;
// 	border-top: 1px solid transparent;
// }
table.table__style{
	width: 100%;
	// border: 1px solid #F0F0F0;
	border-top: 1px solid transparent;
	tr{
		th{
			font-size: 16px !important;
			padding: 15px 10px !important;
			color: $color-text-main;
			// font-family: $font-third-regular;
			font-weight: normal;
		}
		th, td{
			font-size: 12px;
			color: $color-text-small;
			text-align: left;
			padding: 12px;
			// 	border: 1px solid #F0F0F0;
			border-top: 1px solid transparent;
			&:first-child{
				border-left: 1px solid transparent;
			}
			a{
                display: inline-flex;
                color: $color-text-small;
                .ico-pencil{
                	position: relative;
                	right: 7px;
                	top: -2px;
                	&:hover{
                		svg{
                			path{
                				fill: $color-table-icon-hover;
                			}
                		}
                	}
                }
                .ico-delete{
                	position: relative;
                	left: 7px;	
                	&:hover{
                		svg{
                			path{
                				fill: $color-table-icon-hover;
                			}
                		}
                	}
                }
                .ico-info{
                	display: flex;
                	position: relative;
                	left: 4px;
                	&:hover{
                		svg{
                			path{
                				stroke: $color-table-icon-hover;
                			}
                		}
                	}
                }
			}
		}
        .text__center{
        	text-align: center;
        }
		&:nth-child(even){
			background-color: $lightgrey;
		}
	}
}

table.table__popup{
	width: 100%;
	tr{
		th{
			font-size: 16px;
			padding: 15px 10px !important;
			color: $color-table-th;
		}
		td{
			font-size: 14px;
		}
		th, td{
			padding: 12px;
			&:nth-child(-n+4){
				text-align: left
			}
			&:first-child{
				text-align: center;
			}
			a{
                display: inline-flex;
			}
		}
		&:nth-child(even){
			background-color: $lightgrey;
		}
	}
}

/* SEARCH FIELD BESIDE BUTTON */
.field__search__function {
	height: 32px;
	margin-right: 15px;
	width: 188px;
	background: url(/cms/images/ico-luv.svg) no-repeat right 5px center;
	padding: 3px 22px 3px 4px;
	font-size: 11px;
}


// .transaction__tabel{
// 	.content__top{
// 		align-items: flex-end !important;
// 		.content__input__wrapper {
// 			display: flex;
// 			.input-icon .icon__wrapper {
// 				width: 26px;
// 				height: 26px;
// 				border: 1px solid #F0F0F0;
// 			}
// 			.datepick{
// 	    		height: 28px;
// 	    		width: 100px;
// 	    	}
// 			input{
// 				font-size: 10px;
// 				border: 1px solid #F0F0F0;
// 			}
// 		}
// 		.dropdown--select{
// 			width: 150px !important;
// 		}
// 		.content__form{
// 			.content__form__row{
// 				.select2-selection{
// 					border: 1px solid #F0F0F0 !important;
// 				}
// 				select{
// 					height: 28px;
// 				}
// 			}
// 		}
// 		.field__search__function{
// 			border: 1px solid #F0F0F0;
// 		}
// 	}
// 	.table__style{
// 		th{
// 			font-size: 18px;
// 			color: $color-text-main;
// 		}
// 		td, th{
// 			text-align: left;
// 		}
// 	}
// 	.transaction__status{
// 		padding-left: 13px;
// 		position: relative;
// 		&:before{
// 			content:"";
// 			width: 7px;
// 			height: 7px;
// 			border-radius: 100%;
// 			position: absolute;
// 			left: 0;
// 			top: 0;
// 			bottom: 0;
// 			margin: auto;
// 		}
// 		&.status__waiting{
// 			&:before{
// 				content: ""	;
// 				background-color: #FFE183;
// 			}
// 		}
// 		&.status__paid{
// 			&:before{
// 				content: "";
// 				background-color: #44C7F4;	
// 			}
// 		}
// 		&.status__cancelled{
// 			&:before{
// 				content: "";
// 				background-color: #F37373;	
// 			}
// 		}
// 		&.status__expired{
// 			&:before{
// 				content: "";
// 				background-color: #D5D5D5;	
// 			}
// 		}
// 		&.status__draft{
// 			&:before{
// 				content: "";
// 				background-color: #000000;	
// 			}
// 		}
// 		&.status__waiting__checkout{
// 			&:before{
// 				content: "";
// 				background-color: #B8E986;	
// 			}
// 		}
// 	}
// }


// /* TABLE TRANSACTION INSIDE FORM */
// .table__invoice{
	
// }
// .table__row {
// 	display: flex;
// 	justify-content: space-between;
// 	margin-bottom: 3px;
// }
// .table__addon {
// 	width: 100%;
// 	.table__addon__row {
// 		display: flex;
// 		justify-content: space-between;
// 		margin-bottom: 3px;
// 	}
// }
// .custom__addon{
// 	.table__addon__column{
// 		width: 100%;
// 		.custom__addon__row {
// 			display: flex;
// 			justify-content: space-between;
// 			margin-bottom: 3px;
// 		}
// 	}
// }
// .invoice__price {
// 	margin-left: 5px;
// 	min-width: 90px;
// 	display: flex;
// 	justify-content: space-between;
// 	&:before{
// 		content: "- IDR";
// 		margin-right: 5px;
// 	}
// }