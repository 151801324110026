.page__code{
	.popup__mask{
		.popup__wrapper{
			.popup__layer{
				.content--header{
					.content__header__desc{
						.header__label{
							 width: 200px;
						}
					}
				}
			}
		}
	}
	
	.new__form__field__single{
		.input--radio--ul{
			li{
				.check{
					top: -3px;
				}
			}
		}
	}

	.first__purchase__discount{
		input{
			// height: 26px;
			width: 40px;
			padding: 3px;
			text-align: center;
		}
		span{
			font-size: 18px;
		}
	}
	.field__idr{
		position: relative;
		input{
			// height: 26px;
			text-align: right;
		}
		span{
			text-transform: uppercase;
			font-size: 12px;
			left: 5px;
			position: absolute;
			color: #9B9B9B;
		}
		p{
			font-size: 18px;
			margin: 0;
			padding: 0;
		}
	}

	#select-promo-type{
		width: 150px;
	}

	.fadeIn-transition{
		transition: all .3s ease-in;
	}
	.fadeIn-enter{
		opacity: 0;
	}
	.fadeIn-leave{
		display: none;
	}

	#list-product{
		display: none;
	}

	.view__more{
		font-size: 14px;
		color: $semilightgrey;
		line-height: 30px;
		&:hover{
			text-decoration: underline;
		}
	}
}


.promo__code__random__code{
	background: white;
	height: 100vh;
	.header__section{
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 2px solid $lightgrey;
		padding: 15px 30px;
		.header--btn{
			text-transform: uppercase;
		}
	}
	.content__section{
		padding: 20px 30px;
		label{
			display: block;
			font-size: 14px;
			line-height: 25px;
			color: $color-popup-text-label;
		}
		.header__label{
			width: 200px;
			display: inline-block;
		}
		.random__code{
			display: flex;
			.random__code__list{
				width: calc(100% - 200px);
				max-height: calc(100vh - 266px);
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				.random__code__item {
					display: block;
					width: 250px;
				}
			}
		}
	}
	.footer__section{
		padding: 20px 30px;
		.btn__back__promo{
			padding: 5px 7px;
			border: 1px solid $charcoal;
			font-weight: 600;
			background: #fff;
			transition: all .3s;
			&:hover{
				background: darken(#fff, 5%);
			}
		}
	}
}