.seo__page{
	.content__bottom{
		padding: 15px;
	}
	p{
		margin: 0;
	}

    .new__form__field{
        textarea{
            width: 500px;
            height: 150px;
            resize: none;
        }
    }
}

.meta__attribute__field {
    margin-bottom: 10px;
}
.meta__label {
	font-family: $font-seo-text-label;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: $color-seo-text-label;
    letter-spacing: 2.57px;
}
.meta__content{
	font-size: 13px;
	line-height: 23px;
}
.btn__edit__seo{
    height: 26px;
    padding: 0 15px;
    background: #fff;
    display: flex;
    align-items: center;
    font-family: $font-seo-text-label;
    font-size: 11px;
    text-transform: uppercase;
    transition: all .3s;
    letter-spacing: 0.3px;
    border: 1px solid $color-seo-border-button;
    &:hover{
    	background: darken(#fff, 5%);
    }
    &.btn__disable{
    	border: 1px solid darken(#f7f7f7, 2%);
    }
}

