.datepicker{
	border-radius: 0 !important;
}
.datepicker--nav{
	border-bottom: none !important;
	margin: 15px 10px 0px 10px;
	padding: 0 !important;
}
.datepicker--pointer{
	display: none !important;
}
.datepicker--nav-title{
	text-transform: uppercase;
	color: $color-datepicker-text-title;
	i{
		color: inherit !important;
	}
}
.datepicker--nav-action{
	svg{
		path{
			fill: none;
			stroke: $color-datepicker-arrow;
		}
	}
}
.datepicker--day-name{
	color: $color-datepicker-text-day-title !important;
}
.datepicker--content{
	padding: 0 !important;
    margin: 20px;
}
.datepicker--cell-day{
	border-radius: 100% !important;
	height: 29px !important;
	&.-focus-{
		background: $color-datepicker-date-hover !important;
		color: #fff !important;
	}
	&.-disabled-{
		&.-focus-{
			background: #ffffff !important;
			color: #aeaeae !important;
		}
	}
}
.datepicker--cell-month, .datepicker--cell-year{
	color: $color-datepicker-date;
	&.-focus-{
		background: $color-datepicker-date-hover !important;
		color: #fff !important;
	}
}
.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade-{
	visibility: hidden;
}
.datepicker--cell.-current- {
	color: $color-datepicker-date-current;
}
/* .datepicker--cell.-current-{
	cursor: default;
	pointer-events: none;
} */