/* Proxima Nova */
@font-face {
  font-family:'proximanova.bold';
  src: url('/fonts/proximanova/proximanova.bold.eot');
  src: url('/fonts/proximanova/proximanova.bold.eot?#iefix') format('embedded-opentype'),
       url('/fonts/proximanova/proximanova.bold.woff2') format('woff2'),
       url('/fonts/proximanova/proximanova.bold.woff') format('woff'),
       url('/fonts/proximanova/proximanova.bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family:'proximanova.light';
  src: url('/fonts/proximanova/proximanova.light.eot');
  src: url('/fonts/proximanova/proximanova.light.eot?#iefix') format('embedded-opentype'),
       url('/fonts/proximanova/proximanova.light.woff2') format('woff2'),
       url('/fonts/proximanova/proximanova.light.woff') format('woff'),
       url('/fonts/proximanova/proximanova.light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family:'proximanova.regular';
  src: url('/fonts/proximanova/proximanova.regular.eot');
  src: url('/fonts/proximanova/proximanova.regular.eot?#iefix') format('embedded-opentype'),
       url('/fonts/proximanova/proximanova.regular.woff2') format('woff2'),
       url('/fonts/proximanova/proximanova.regular.woff') format('woff'),
       url('/fonts/proximanova/proximanova.regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family:'proximanova.semibold';
  src: url('/fonts/proximanova/proximanova.semibold.eot');
  src: url('/fonts/proximanova/proximanova.semibold.eot?#iefix') format('embedded-opentype'),
       url('/fonts/proximanova/proximanova.semibold.woff2') format('woff2'),
       url('/fonts/proximanova/proximanova.semibold.woff') format('woff'),
       url('/fonts/proximanova/proximanova.semibold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


/* fontawesome */
@font-face {
  font-family: 'FontAwesome';
  src: url('/fonts/fontawesome/fontawesome-webfont.eot?v=4.5.0');
  src: url('/fonts/fontawesome/fontawesome-webfont.eot?#iefix&v=4.5.0') format('embedded-opentype'), 
     url('/fonts/fontawesome/fontawesome-webfont.woff2?v=4.5.0') format('woff2'), 
     url('/fonts/fontawesome/fontawesome-webfont.woff?v=4.5.0') format('woff'), 
     url('/fonts/fontawesome/fontawesome-webfont.ttf?v=4.5.0') format('truetype'), 
     url('/fonts/fontawesome/fontawesome-webfont.svg?v=4.5.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}