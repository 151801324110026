.selectric-wrapper {
    .selectric{
        background: #FFFFFF;
        border: 1px solid $color-border-form;
        .label{
            font-size: 12px;
            color: $color-header-dropdown-title;
        }
        .button{
            &:after{
                content: "";
                background: url(../images/logo-arrow.png) no-repeat !important;
                width: 12px;
                height: 7px;
                border: none;
                left: 5px;
            }
        }
    }
    .selectric-items{
        background: #FFFFFF;
        -webkit-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        -o-transform: translateY(-20px);
        transform: translateY(-20px);
        li{
            padding: 8px 15px;
            color: #5b6581;
        }
    }
}
.selectric-open{
    .selectric-items{
        margin: 10px 0px;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        -webkit-transition: .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transition: .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
}



.header__status__dropdown{
    .selectric-wrapper{
        .selectric{
            border: 1px solid $color-border;
            .label{
                line-height: 35px;
                height: 35px;
                font-family: $font-main;
                font-size: 13px;
                color: $color-text-main;
                font-weight: bold;
                margin: 0 38px 0 15px;
            }
            .button{
                height: 35px;
                &:after{
                    content: "";
                    width: 0;
                    height: 0;
                    position: absolute;
                    top: 50%;
                    margin-top: -3px;
                    border-width: 6px 6px 0 6px;
                    border-style: solid;
                    border-color: #202e4c transparent;
                    background: none;
                }
            }
        }
        .selectric-items{
            li{
                font-family: $font-main;
                color: $color-text-main;
                font-size: 12px;
                &.selected{
                    background-color: inherit;
                }
                &:hover{
                    background-color: $color-bg-hover;
                }
            }
        }
    }
}

/* ------- Dropdown header bar -------- */
.header__title__dropdown{
    .selectric-wrapper{
        margin-left:25px;
        .selectric{
            border: none;
            background: none;
            width: 250px;
            .label{
                font-family: $font-third-regular;
                margin: 0;
                line-height: 2;
                height: auto;
                font-size: 22px;
                text-transform: uppercase;
            }
            .button{
                display: none !important;
            }
        }
        &.selectric-hover{
            .label{
                color: #19243C;
            }
        }
        .selectric-items{
        	li{
        		font-family: $font-main;
        		color: rgba(32, 46, 76, 0.5);
        		font-weight: 600;
        		font-size: 13px;
        		padding: 5px 15px;
        		&.selected{
        			color: $color-header-dropdown-text;
        			background: #ffffff;
        		}
        		&:hover{
        			background: #ffffff;
        		}
        	}
        }
    }
    .selectric-open{
		.selectric-items{
			border-radius: 5px;
    		border: 1px solid $color-border-form;
            margin: 1px 0px;
			.selectric-scroll{
				border-radius: 5px;
			}
			&:after{
				content: "";
				position: absolute;
				top: -9px;
			    left: 12px;
			    width: 0px;
			    height: 0px;
				margin-top: 4px;
    			border-bottom: 6px solid rgb(255, 255, 255);
    			border-left: 6px solid transparent;
			    border-right: 6px solid transparent;
			}
			&:before{
			    content: "";
			    position: absolute;
			    top: -9px;
			    left: 12px;
			    width: 0px;
			    height: 0px;
			    margin-top: 3px;
			    border-bottom: 6px solid rgb(173, 173, 191);
			    border-left: 6px solid transparent;
			    border-right: 6px solid transparent;
			}
		}
    }
}


/* ---------------------------------- */