.popup__mask{
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .3s ease;
    .popup__wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        transition: all .3s ease;
        .popup__layer{
            // width: 40%;
            width: 700px;
            background-color: #fff;
            border-radius: 2px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
            transition: all .3s ease;
            .popup__layer--header{
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 2px solid $lightgrey;
            }
            .popup__layer--content{
                padding: 20px 30px;
                .new__form__field{
                    label{
                        color: $color-popup-text-label;
                    }
                }
            }
            .popup__layer--header{
                padding: 15px 30px;
                .btn__popup__close{
                    position: relative;
                    font-size: 32px;
                    width: 15px;
                    height: 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $color-popup-button-close;
                    transition: all .2s ease-out;
                    &:hover{
                        color: darken($color-popup-button-close, 20%);
                    }
                }
            }
            .popup__notif{
                height: 0px;
                opacity: 0;
                background-color: $bluelight;
                color: rgba(255, 255, 255, 0.8);
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                // box-shadow: rgba(0, 0, 0, 0.498039) 0px 2px 4px -2px inset;
                box-shadow: inset 0 3px 6px -6px rgba(0, 0, 0, 0.8);
                transition: all .3s;
                span{
                    font-size: 12px;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                }
                &.open{
                    height: 40px;
                    opacity: 1;
                }
            }
            .content--header{
                padding: 10px 0 20px 0;
                .content__header__desc{
                    label{
                        display: block;
                        font-size: 14px;
                        line-height: 25px;
                        color: $color-popup-text-label;
                    }
                    .header__label{
                        width: 100px;
                        display: inline-block;
                    }
                }
            }
            .content--header, .content--main{
                padding-bottom: 10px;
            }
            .content--main{
                display: flex;
                flex-direction: column;
                .main__row{
                    padding: 0px 0 10px 0;
                    position: relative;
                    display: flex;
                    .main__label{
                        width: 200px;
                        display: inline-block;
                    }
                    .main__data{
                        width: calc(100% - 200px);
                        display: block;
                    }
                }
                .main__hr{
                    width: 100%;
                    border: 1px solid transparent;
                    border-bottom: 1px solid #E1E1E1;
                }
                .content__group__title{
                    font-family: $font-form-group-title;
                    font-size: 14px;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    color: $color-text-form-group;
                    margin: 8px 0;
                }
            }
            .table__popup__wrapper{
                margin-top: 15px;
                box-shadow: 0px 1px 4px 0px rgba(32,45,77,0.20);
                border: 1px solid $lightgrey;
                overflow-y: auto;
                max-height: 350px;
            }
            .header--title{
                h1{
                    font-family: $font-popup-title;
                    font-size: 26px;
                    margin: 15px 0 10px 0;
                    color: $color-popup-title;
                    font-weight: normal;
                    letter-spacing: 0;
                    text-transform: capitalize;
                }
            }
            .form__password{
                width: 50%;
                margin: 0px auto;
                .new__form__btn{
                    display: flex;
                    justify-content: center;
                    margin-top: 20px;
                }
                .new__form__field {
                    margin: 15px 0px;
                    width: 100%;
                    label{
                        // font-family: 'Open Sans', Arial, Helvetica, Sans-serif, Verdana, Tahoma;
                    }
                }
            }
            .title__name{
                font-weight: 400;
            }
            .title__popup--name, .title__name{
                font-family: $font-main;
            }
            .title__popup--name{
                b{
                    font-weight: normal;
                }
            }
        }
    }
}

.popup__mask__alert{
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-color: rgba(0, 0, 0, .5);
    transition: opacity .3s ease;
    .popup__wrapper__alert{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        transition: all .3s ease;
        .popup__layer__alert{
            width: 420px;
            background-color: #fff;
            border-radius: 2px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
            position: relative;
            transition: all .3s ease;
            .alert__message__wrapper{
                text-align: center;
                .alert__message{
                    padding: 27px 0px 0px 0px;
                    label{
                        color: $color-text-small;
                        font-size: 13px;
                        position: relative;
                        line-height: 21px;
                    }
                    h3{
                        margin: 5px 0px;
                        color: $color-popup-title;
                    }
                }
                .alert__message__btn{
                    padding: 25px 0px;
                    .new__form__btn{
                        display: flex;
                        justify-content: center;
                        // .btn__form__reset{
                        //     color: $color-main;
                        // }
                        // .btn__form__create{
                        //     color: #ffffff;
                        // }
                    }
                }
                .alert__message__close{
                    background: $lightgrey;
                    border-radius: 100%;
                    padding: 5px 10px;
                    position: absolute;
                    top: -12px;
                    right: -12px;
                    border: 1px;
                    box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.12);
                    -moz-user-select: none;
                    -webkit-user-select: none;
                    -ms-user-select: none; 
                    user-select: none;
                    -o-user-select: none;
                    &:after{
                        content: "x";
                    }
                    &:hover{
                        &:after{
                            opacity: 0.5;
                        }
                    }
                }
            }
        }
    }
}

.popup--changepass {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
  &.is-visible {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s 0s, visibility 0s 0s;
    .popup--changepass__layer {
        transform: translateY(0);
    }
  }
  .popup--changepass__layer {
      transform: translateY(-40px);
      transition-property: transform;
      transition-duration: 0.3s;
  }
}
// .popup__mask__alert{
//   opacity: 0;
//   visibility: hidden;
//   transition: opacity 0.3s 0s, visibility 0s 0.3s;
//   &.is-visible {
//     opacity: 1;
//     visibility: visible;
//     transition: opacity 0.3s 0s, visibility 0s 0s;
//     .popup__layer__alert{
//         transform: translateY(0);
//     }
//   }
//   .popup__layer__alert{
//       transform: translateY(-40px);
//       transition-property: transform;
//       transition-duration: 0.3s;
//   }
// }

.popupContainer {
    transform: initial;
    backface-visibility: visible;
}


.modal-enter, .modal-leave {
    opacity: 0;
}

.modal-enter .popup__wrapper,
.modal-leave .popup__wrapper {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.modal-enter .popup__layer__alert,
.modal-leave .popup__layer__alert {
    transform: translateY(-40px);
      transition-property: transform;
      transition-duration: 0.3s;
}


/* (1024px)----------- */
@media only screen 
and (max-width : 1280px) {
    .popup__mask{
        .popup__wrapper{
            .popup__layer{
                width: 60%;
            }
        }
    }
}