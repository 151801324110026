body {
	font-family: $font-main;
	background: #F3F4F6;
	&.fixed{
		width: 100%;
	}
}

.fixed{
	position:fixed;
}

.flex{
	display: flex !important;
}
.flex-align-baseline{
	display: flex !important;
    align-items: baseline !important;
}
.flex-align-center{
	display: flex !important;
    align-items: center !important;
}
.flex-center{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.flex-content-between{
    display: flex !important;
    justify-content: space-between !important;
}
.flex-between{
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}
.flex-end{
	display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;
}
.flex-start{
	display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
}
.left{
    float: left;
}
.right{
    float: right;
}
.clearfix{
    clear: both;
}
.marginside10{
	margin: 0 10px;
}
.required{
	color: #F37373;
	font-size: 10px;
}


/* FOR EDITABLE CONTENT GLOBAL */
.content__edit__hover{
    position: relative;
    &:hover{
        color: $color-text-hover;
        text-decoration: underline;
        &:after{
            content: "";
            background: url(/cms/images/ico-pencil.svg) no-repeat;
            position: absolute;
            width: 13px;
            height: 12px;
            top: 0;
            bottom: 0;
            margin: auto 0 auto 5px;
            font-size: 15px;
        }
    }
}
/* END FOR EDITABLE CONTENT GLOBAL */


/* PAGINATION */
.paging-card{
	position: relative;
    display: flex;
    justify-content: center;
	ul{
		display: flex;
		li{
			font-size: 11px;
			background: $color-paging-bg;
			border: 1px solid transparent;
			color: #212e4d;
			height: 30px;
		    width: 30px;
		    display: flex;
		    align-items: center;
		    justify-content: center;
		    &:last-child{
		    	border-right: none;
		    }
			a{
				color: $color-text-small;
				text-decoration: none;
				padding: 10px;
			}
			&:hover{
				background: darken($color-paging-bg, 10%);
				// border: 1px solid darken($color-paging-bg, 20%);
				a{		
					color: $color-paging-text-hover !important;
				}
			}
		}
		.active{
			// background: #fff;
			border: 1px solid rgba(70, 70, 70,0.5);
			a{
				color: $color-text-small2 !important;
				cursor: default;
			}
			&:hover{
				background: darken(#fff, 1%);
				a{
					color: $color-text-hover !important;
				}
			}
		}
	}
}


/* STYLE FOLDER TAB */
.folder--nav{
	display: flex;
	margin: 15px 1%;
	position: relative;
	z-index: 0;
	&.folder--hidden{
		visibility: hidden;
		height: 0;
		.folder--nav--link{
			height: 0;
			visibility: hidden;
			i{
				height: 0;
				visibility: hidden;
			}
		}
	}
	.folder--nav--link{
		display: flex;
		height: 50px;
		visibility: visible;
		margin-right: 15px;
		background-color: rgba(255, 255, 255, 1);
		box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.05);
		transition: all .2s;
		&.folder--selected{
			cursor: default;
			pointer-events: none;
			i{
				background-color: $color-button-hover;
			}
		}
		i{
			display: inline-flex;
			align-items: center;
			padding: 0 12px;
			visibility: visible;
			height: 50px;
			background-color: $color-button-bg;
			transition: all .2s;
		}
		.folder--nav--link--span{
			display: inline-flex;
			align-items: center;
			padding: 18px 40px 18px 15px;
			font-size: 14px;
		}
		&:hover{
			i{
				background-color: $color-button-hover;
			}
		}
	}
}

// TAGS
.tag__wrapper{
	display: flex;
	position: relative;
}
.tag--list{
	position: relative;
	width: 85%;
	height: 40px;
	margin: 10px 0 0 0;
	overflow: hidden;
	transition: all .3s;
}
.tag{
    display: flex;
    flex-wrap: wrap;
    li{
        margin: 10px 3px 10px 0;
        a{
            background: $color-tags-bg;
            font-size: 11px;
            color: $color-tags-text;
            padding: 10px;
        }
    }
}


/* data not found */
.notfound__wrapper{
    text-align: center;
    position: relative;
    top: 40px;
    .notfound__img{
    	svg{
    		width: 190px
    	}
    }
    h2{
        font-weight: normal;
        font-size: 23px;
    }
    p{
        margin: 10px 0;
        color: $darkgrey;
        font-size: 13px;
    }
}
/* -------------- */

@media only screen 
and (max-width : 1366px) {
	/* data not found */
	.notfound__wrapper{
	    text-align: center;
	    position: relative;
	    top: 40px;
	    .notfound__img{
	        svg{
	        	// width: 190px
	        }
	    }
	    h2{
	        font-weight: normal;
	        font-size: 23px;
	    }
	    p{
	        margin: 10px 0;
	        color: $darkgrey;
	        font-size: 13px;
	    }
	}
	/* -------------- */
}

@media only screen 
and (max-width : 1024px) {
	.folder--nav{
		.folder--nav--link{
			height: 40px;
			i{
				padding: 10px;
				height: 20px;
			}
			.folder--nav--link--span{
				padding: 10px;
				font-size: 12px;
			}
		}
	}
}